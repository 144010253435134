import { clone, lensPath, pipe, set } from 'ramda'
import { ResponsePayloadSuccessful, ReducerCreator } from '~utils'
import { curriedActions } from './FeaturedList.actions'
import { Data, State } from './FeaturedList.types'
import { DEFAULT_DATA } from './FeaturedList.consts'

const isLoadingLens = lensPath<State, State['isLoading']>(['isLoading'])
const isFirstLoadLens = lensPath<State, State['isFirstLoad']>(['isFirstLoad'])
const dataLens = lensPath<State, State['data']>(['data'])

const setIsLoading = set(isLoadingLens)

export const reducer = new ReducerCreator<State, keyof typeof curriedActions>()
  .add(curriedActions.onFetchFeaturedListTrigger, (state: State) => setIsLoading(true, state))

  .add(curriedActions.onFetchFeaturedListSuccess, (state: State, { data = DEFAULT_DATA }: ResponsePayloadSuccessful<Data>) =>
    pipe(
      //
      set(isLoadingLens, false),
      set(isFirstLoadLens, false),
      set(dataLens, clone(data))
    )(state)
  )

  .add(curriedActions.onFetchFeaturedListError, (state: State) =>
    pipe(
      //
      set(isLoadingLens, false),
      set(isFirstLoadLens, false)
    )(state)
  )

  .build()
