import React, { useCallback, useContext, useState, useMemo } from 'react'
import IDM from '~components/IDM'
import { useAuth } from '~components/AuthProvider'

interface IDMContextInfo {
  logout: (onSuccess?: () => void) => void
  login: (onSuccess?: () => void) => void
  register: (onSuccess?: () => void) => void
}

const IDMContext = React.createContext<IDMContextInfo>({
  logout: () => {},
  login: () => {},
  register: () => {},
})

export const IDMProvider: React.FC = ({ children }) => {
  const [isIDMOpen, setIDMOpen] = useState(false)
  const [idmType, setIDMType] = useState<'login' | 'register' | 'logout'>('login')
  const { signIn, signOut } = useAuth()
  const [idmCallback, setIDMCallback] = useState<(() => void) | undefined>(undefined)

  const onLogIn = useCallback((cb?: () => void) => {
    setIDMOpen(true)
    setIDMType('login')
    setIDMCallback(cb)
  }, [])

  const onRegister = useCallback((cb?: () => void) => {
    setIDMOpen(true)
    setIDMType('register')
    setIDMCallback(cb)
  }, [])

  const onLogOut = useCallback((cb?: () => void) => {
    setIDMOpen(true)
    setIDMType('logout')
    setIDMCallback(cb)
  }, [])

  const onCloseIDM = useCallback(() => {
    setIDMOpen(false)
  }, [])

  const onSuccessIDM = useCallback(async () => {
    setIDMOpen(false)

    if (idmType === 'register' || idmType === 'login') {
      await signIn()
    } else {
      await signOut()
    }

    if (idmCallback) {
      idmCallback()
    }
  }, [idmType, idmCallback, signIn, signOut])

  return (
    <IDMContext.Provider value={useMemo(() => ({ logout: onLogOut, login: onLogIn, register: onRegister }), [onLogOut, onLogIn, onRegister])}>
      {isIDMOpen && <IDM startAt={idmType} isVisible={true} onClose={onCloseIDM} onSuccess={onSuccessIDM} />}
      {children}
    </IDMContext.Provider>
  )
}

export function useIDM() {
  return useContext(IDMContext)
}
