import { Breakpoint, breakpointStarting, linkReset, rem, spacing } from '@unbounded/unbounded-components'
import styled from 'styled-components'
import { SectionLargeHeadline } from '../LandingPage.styles'

export const Container = styled.section``

export const Title = styled(SectionLargeHeadline)`
  max-width: ${rem(300)};

  @media (${breakpointStarting(Breakpoint.md)}) {
    max-width: ${rem(550)};
  }
`
export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing(1)};
  max-width: ${rem(1000)};
  margin: ${spacing(2)} auto;

  @media (${breakpointStarting(Breakpoint.sm)}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (${breakpointStarting(Breakpoint.lg)}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const Item = styled.a`
  ${linkReset};
`
