import styled from 'styled-components/macro'
import { Breakpoint, breakpointStarting, FlexibleContainer, rem, spacing } from '@unbounded/unbounded-components'
import { headerHeight, headerMobileBreakpoint } from '../Header'

export const Container = styled.div`
  position: relative;
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (${headerMobileBreakpoint}) {
    padding-top: ${rem(headerHeight)};
  }
`

export const MiddleContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  position: relative;
  // Hack to make it relative for fixed children
  transform: translate(0, 0);
`

export const Wrapper = styled(FlexibleContainer)`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: ${spacing(1.5)} ${spacing(1)} ${spacing(2.25)};

  @media (${breakpointStarting(Breakpoint.md)}) {
    flex-direction: row;
  }
`

export const Inner = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
`
