import React, { useContext, useMemo, useState } from 'react'
import { AVAILABLE_DIALOGS } from './Modal.consts'
import { ModalContext } from './Modal.context'
import { ModalType } from './Modal.types'

export const ModalProvider: React.FC = ({ children }) => {
  const [modal, handleModal] = useState<ModalType>()

  const data = useMemo(() => ({ modal, handleModal }), [modal, handleModal])

  return <ModalContext.Provider value={data}>{children}</ModalContext.Provider>
}

export const ModalConsumer = () => {
  const modalInfo = useContext(ModalContext)

  if (modalInfo?.modal) {
    const Component = AVAILABLE_DIALOGS[modalInfo.modal.type]

    const {
      data: { onConfirm, onClose, ...rest },
    } = modalInfo.modal

    const onConfirmHandler = () => {
      onConfirm?.()
      modalInfo?.handleModal()
    }

    const onCloseHandler = () => {
      onClose?.()
      modalInfo?.handleModal()
    }

    /* eslint-disable */
    return (
      Component && (
        <Component
          {...{
            onConfirm: onConfirmHandler,
            onClose: onCloseHandler,
            ...rest,
          }}
        />
      )
    )
  }

  return null
}
