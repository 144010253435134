import { IconName } from '@unbounded/unbounded-components'
import React from 'react'
import * as Styled from './BurgerButton.styles'

interface Props {
  isOpen: boolean
  onClick: () => void
}

const BurgerButton: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(({ isOpen, onClick }, ref) => (
  <Styled.Container onClick={onClick} ref={ref}>
    <Styled.Inner>
      <Styled.Icon name={IconName.menu} $isActive={!isOpen} />
      <Styled.Icon name={IconName.times} $isActive={isOpen} />
    </Styled.Inner>
  </Styled.Container>
))

BurgerButton.displayName = 'BurgerButton'

export default BurgerButton
