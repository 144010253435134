import { ApolloClient, InMemoryCache, Reference } from '@apollo/client'
import { RelayFieldPolicy } from '@apollo/client/utilities/policies/pagination'
import { relayStylePagination } from '@apollo/client/utilities'

export type ExplorerCacheField = 'transactions' | 'blocks'

export type ExplorerCacheTypePolicies = Record<ExplorerCacheField, RelayFieldPolicy<Reference>>

const cacheTypePolicies: ExplorerCacheTypePolicies = {
  transactions: relayStylePagination(),
  blocks: relayStylePagination(),
}

const getExplorerClient = (projectExplorerUri: string) =>
  new ApolloClient({
    uri: projectExplorerUri,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: cacheTypePolicies,
        },
      },
    }),
  })

export default getExplorerClient
