import React, { useReducer, useState } from 'react'
import { map } from 'ramda'
import { restApiClient, RestApiResponse, extendResponse, isExtendedResponse, responseValidators } from '~api'
import { logger } from '~logger'

interface Action<AT> {
  type: AT
  // eslint-disable-next-line
  value: any
}

export const curriedActionCreator = <P, T extends string>(type: T) => {
  type InnerAction = {
    type: T
    value: P
  }
  // to fix - type
  const innerActionCreator = (dispatch: React.Dispatch<InnerAction>) => (value: P) => {
    dispatch({ type, value })
  }

  innerActionCreator.toString = (): T => type

  return innerActionCreator
}

export class ReducerCreator<STATE, ACTIONS> {
  // eslint-disable-next-line
  public actionsReducers: { [type: string]: any } = {}

  // eslint-disable-next-line
  public add(action: any, reducer: any) {
    this.actionsReducers[action.toString()] = reducer

    return this
  }

  public build() {
    const ref = { ...this.actionsReducers }

    // eslint-disable-next-line
    // @ts-ignore
    return (state: STATE, action: Action<ACTIONS>): STATE => (ref[action.type] ? ref[action.type](state, action.value) : state)
  }
}

interface BuildRequest<ActionName, Data, ExtraArg> {
  // eslint-disable-next-line
  actions: Record<string, any>
  actionName: ActionName
  // eslint-disable-next-line
  payload?: any
  urlPath: string
  method?: 'post' | 'delete' | 'put'
  onSuccess?: (data: ResponsePayloadSuccessful<Data, ExtraArg>) => void
  onFailure?: (data: ResponsePayloadErroneous<ExtraArg>) => void
  extraArgument?: ExtraArg
}

export interface ResponsePayloadSuccessful<Data, ExtraArg = undefined> {
  urlPath: string
  data: Data
  extraArgument?: ExtraArg
}

export interface ResponsePayloadErroneous<ExtraArg = undefined> {
  urlPath: string
  error?: string
  extraArgument?: ExtraArg
}

export const performAsyncAction = <ActionName, Data, ExtraArg = undefined>({
  actions,
  payload,
  actionName,
  urlPath,
  onFailure,
  onSuccess,
  method,
  extraArgument,
}: BuildRequest<ActionName, Data, ExtraArg>) => {
  const request = (method && restApiClient[method]) || restApiClient[payload ? 'post' : 'get']

  return {
    urlPath,
    request: request<RestApiResponse<Data> | Data>(urlPath, payload)
      .then(response => {
        let responseData = response?.data

        if (!isExtendedResponse<Data>(responseData)) {
          responseData = extendResponse(responseData)
        }

        const { error, data } = responseData
        const validatorKey = Object.keys(responseValidators).find(re => new RegExp(re).test(urlPath))
        const hasData = validatorKey ? responseValidators[validatorKey](data) : responseValidators.default(data)

        if (response.status >= 200 && response.status < 300 && !error && hasData) {
          actions[`${actionName}Success`]?.({ urlPath, data, extraArgument })
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSuccess?.({ urlPath, data, extraArgument })
        } else {
          actions[`${actionName}Error`]?.({ urlPath, error, extraArgument })
          onFailure?.({ urlPath, error, extraArgument })
        }

        return data
      })
      .catch(errorInfo => {
        const error: string = errorInfo?.message

        actions[`${actionName}Error`]?.({ urlPath, error, extraArgument })
        onFailure?.({ urlPath, error, extraArgument })

        logger.error({
          category: `${actionName}`,
          message: error,
        })

        return undefined
      }),
  }
}

// eslint-disable-next-line
export const useReducerHook = <S, A>(initialState: S, curriedActions: A, reducer: any, initialStateCreator?: () => S) => {
  // eslint-disable-next-line
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, initialState, initialStateCreator)

  const [dispatchableActions] = useState<A>(
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    () => map((a: any) => a(dispatch), curriedActions)
  )

  return { state: state as S, dispatch, actions: dispatchableActions }
}
