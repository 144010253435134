import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import * as Styled from './Layout.styles'
import * as CommonStyled from './Layout.common.styles'

interface CommonStyledProps {
  PreHeader: typeof CommonStyled.PreHeader
  Header: typeof CommonStyled.Header
  Title: typeof CommonStyled.Title
  Content: typeof CommonStyled.Content
  Footer: typeof CommonStyled.Footer
  Sidebar: typeof CommonStyled.Sidebar
}

interface ComponentProps {
  leftSidebar?: React.ReactNode
  rightSidebar?: React.ReactNode
  externalSidebar?: React.ReactNode
}

const Layout: React.FC<ComponentProps> & CommonStyledProps = ({ leftSidebar, rightSidebar, externalSidebar, children }) => (
  <Styled.Container>
    <Header />
    <Styled.MiddleContainer>
      {externalSidebar}

      <Styled.Wrapper as="main">
        {leftSidebar}
        <Styled.Inner>{children}</Styled.Inner>
        {rightSidebar}
      </Styled.Wrapper>
    </Styled.MiddleContainer>
    <Footer />
  </Styled.Container>
)

Layout.PreHeader = CommonStyled.PreHeader
Layout.Header = CommonStyled.Header
Layout.Title = CommonStyled.Title
Layout.Content = CommonStyled.Content
Layout.Sidebar = CommonStyled.Sidebar
Layout.Footer = CommonStyled.Footer

export default Layout
