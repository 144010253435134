import { FieldPolicy, Reference } from '@apollo/client'
import { RelayFieldPolicy, TExistingRelay, TRelayEdge, TRelayPageInfo } from '@apollo/client/utilities/policies/pagination'
import { mergeDeep } from '@apollo/client/utilities'
import { DataFetchDirection } from './types'

type KeyArgs = FieldPolicy['keyArgs']

function makeEmptyData<TNode = Reference>(): TExistingRelay<TNode> {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  }
}

// Returns any unrecognized properties of the given object.
function getExtras(obj: Record<string, unknown>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { edges, pageInfo, ...rest } = obj

  return rest
}

const DEFAULT_KEY_ARGS = ['first', 'last']

// Basically just a copy of `relayStylePagination` policy helper from @apollo/client, but adjusted with re-pagination
// See more https://www.apollographql.com/docs/react/pagination/cursor-based#relay-style-cursor-pagination
// relayStylePagination - https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L95
export function cursorPaginationFieldPolicy<TNode = Reference>(keyArgs: KeyArgs = DEFAULT_KEY_ARGS): RelayFieldPolicy<TNode> {
  return {
    keyArgs: Array.isArray(keyArgs) ? [...DEFAULT_KEY_ARGS, ...keyArgs] : keyArgs,
    read(existing, { canRead, readField, args }) {
      if (!existing) return existing

      const edges: TRelayEdge<TNode>[] = []
      let firstEdgeCursor = ''
      let lastEdgeCursor = ''

      const direction = args?.last ? DataFetchDirection.START_FROM_LAST : DataFetchDirection.START_FROM_FIRST

      // Ignore as we don't generate types for query yet
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const limit = args.first || args.last || existing.edges.length
      const limitForPage = existing.edges.length % limit || limit

      const edgesForPage =
        direction === DataFetchDirection.START_FROM_FIRST ? existing.edges.slice(-limitForPage) : existing.edges.slice(0, limitForPage)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      edgesForPage.forEach(edge => {
        // Edges themselves could be Reference objects, so it's important
        // to use readField to access the edges.edge.node property.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (canRead(readField('node', edge))) {
          edges.push(edge)
          if (edge.cursor) {
            firstEdgeCursor = firstEdgeCursor || edge.cursor || ''
            lastEdgeCursor = edge.cursor || lastEdgeCursor
          }
        }
      })

      const { startCursor, endCursor } = existing.pageInfo || {}

      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...getExtras(existing),
        edges,
        pageInfo: {
          ...existing.pageInfo,
          // If existing.pageInfo.{start,end}Cursor are undefined or "", default
          // to firstEdgeCursor and/or lastEdgeCursor.
          startCursor: startCursor || firstEdgeCursor,
          endCursor: endCursor || lastEdgeCursor,
        },
      }
    },
    merge(existing, incoming, { args, isReference, readField }) {
      if (!existing) {
        // eslint-disable-next-line no-param-reassign
        existing = makeEmptyData()
      }

      if (!incoming) {
        return existing
      }

      const incomingEdges = incoming.edges
        ? incoming.edges.map(edge => {
            const updatedEdge = { ...edge }

            if (isReference(updatedEdge)) {
              // In case edge is a Reference, we read out its cursor field and
              // store it as an extra property of the Reference object.
              updatedEdge.cursor = readField<string>('cursor', updatedEdge)
            }
            return updatedEdge
          })
        : []

      if (incoming.pageInfo) {
        const { pageInfo } = incoming
        const { startCursor, endCursor } = pageInfo
        const firstEdge = incomingEdges[0]
        const lastEdge = incomingEdges[incomingEdges.length - 1]
        // In case we did not request the cursor field for edges in this
        // query, we can still infer cursors from pageInfo.

        if (firstEdge && startCursor) {
          firstEdge.cursor = startCursor
        }
        if (lastEdge && endCursor) {
          lastEdge.cursor = endCursor
        }
        // Cursors can also come from edges, so we default
        // pageInfo.{start,end}Cursor to {first,last}Edge.cursor.
        const firstCursor = firstEdge && firstEdge.cursor

        if (firstCursor && !startCursor) {
          // eslint-disable-next-line no-param-reassign
          incoming = mergeDeep(incoming, {
            pageInfo: {
              startCursor: firstCursor,
            },
          })
        }
        const lastCursor = lastEdge && lastEdge.cursor

        if (lastCursor && !endCursor) {
          // eslint-disable-next-line no-param-reassign
          incoming = mergeDeep(incoming, {
            pageInfo: {
              endCursor: lastCursor,
            },
          })
        }
      }

      let prefix = existing.edges
      let suffix: typeof prefix = []

      if (args && args.after) {
        // This comparison does not need to use readField("cursor", edge),
        // because we stored the cursor field of any Reference edges as an
        // extra property of the Reference object.
        const index = prefix.findIndex(edge => edge.cursor === args.after)

        if (index >= 0) {
          prefix = prefix.slice(0, index + 1)
          // suffix = []; // already true
        }
      } else if (args && args.before) {
        const index = prefix.findIndex(edge => edge.cursor === args.before)

        suffix = index < 0 ? prefix : prefix.slice(index)
        prefix = []
      } else if (incoming.edges) {
        // If we have neither args.after nor args.before, the incoming
        // edges cannot be spliced into the existing edges, so they must
        // replace the existing edges. See #6592 for a motivating example.
        prefix = []
      }

      const edges = [...prefix, ...incomingEdges, ...suffix]

      const pageInfo: TRelayPageInfo = {
        ...existing.pageInfo,
        ...incoming.pageInfo,
      }

      return {
        ...getExtras(existing),
        ...getExtras(incoming),
        edges,
        pageInfo,
      }
    },
  }
}
