import styled, { css } from 'styled-components/macro'
import {
  BaseColor,
  borderColor,
  Breakpoint,
  breakpointStarting,
  rem,
  spacing,
  themeColor,
  Typography,
  ZLayers,
} from '@unbounded/unbounded-components'

interface ContentProps {
  isCompact?: boolean
}

export const Content = styled.div<ContentProps>`
  position: relative;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;

  ${props =>
    props.isCompact &&
    css`
      @media (${breakpointStarting(Breakpoint.xxl)}) {
        align-self: center;
        max-width: ${rem(1248)};
      }
    `}
`

export const PreHeader = styled.div`
  margin-bottom: ${spacing()};
`

export const Title = styled.h1`
  min-width: ${rem(16)};
  color: ${themeColor(BaseColor.shade_60)};
  ${Typography.titleLarge};
  ${Typography.textRegular};
  word-break: break-word;
`

export const Header = styled.header`
  flex: 0 0 auto;
  margin-bottom: ${spacing()};

  @media (${breakpointStarting(Breakpoint.md)}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${spacing(1.5)};

    ${Title}:not(:only-child) {
      margin-right: ${spacing(1.5)};
    }
  }
`

export const Footer = styled.footer`
  flex: 0 0 auto;
  margin-top: auto;
`

interface SidebarProps {
  side?: 'left' | 'right'
  hasBorder?: boolean
}

const leftSidebarStyle = css<SidebarProps>`
  border-right-width: ${({ hasBorder }) => hasBorder && '1px'};
  padding-right: ${spacing(1.5)};
  margin-right: ${spacing(1.5)};

  @media (${breakpointStarting(Breakpoint.lg)}) {
    padding-right: ${spacing(2.5)};
    margin-right: ${spacing(2.5)};
  }
`

const rightSidebarStyle = css<SidebarProps>`
  border-left-width: ${({ hasBorder }) => hasBorder && '1px'};
  padding-left: ${spacing(1.5)};
  margin-left: ${spacing(1.5)};

  @media (${breakpointStarting(Breakpoint.lg)}) {
    padding-left: ${spacing(2.5)};
    margin-left: ${spacing(2.5)};
  }
`

export const Sidebar = styled.aside.attrs<SidebarProps>(props => ({
  hasBorder: props.hasBorder !== false,
}))<SidebarProps>`
  position: relative;
  z-index: ${ZLayers.sidebar};
  min-width: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  padding-bottom: ${spacing(1.5)};
  margin-bottom: ${spacing(1.5)};
  border-color: ${borderColor};
  border-style: solid;
  border-bottom-width: 1px;

  @media (${breakpointStarting(Breakpoint.md)}) {
    order: initial;
    width: ${rem(248)};
    min-width: ${rem(248)};
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom-width: 0;
    ${({ side }) => (side === 'right' ? rightSidebarStyle : leftSidebarStyle)};
  }
`
