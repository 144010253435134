import { BaseColor, rem, FlexibleContainer, linkReset, spacing, themeColor, ZLayers, Color } from '@unbounded/unbounded-components'
import SVG from 'react-inlinesvg'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { headerHeight, headerMobileBreakpoint } from './Header.consts'

export const Container = styled.div`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${ZLayers.header};
  flex: 0 0 auto;
  background-color: ${themeColor(BaseColor.shade_90)};
  color: ${themeColor(BaseColor.shade_0)};

  @media (${headerMobileBreakpoint}) {
    position: fixed;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid ${themeColor(BaseColor.shade_70)};
  }
`

export const Wrapper = styled(FlexibleContainer)`
  display: flex;
  height: ${rem(headerHeight)};
  padding: 0 ${spacing(1)};
`

export const Inner = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ${spacing(-0.5)};
  margin-right: ${spacing(-0.5)};
`

export const Logo = styled(SVG)`
  display: block;
  width: ${spacing(7)};
  height: ${spacing(4)};
  padding: 0 ${spacing(0.5)};
  color: ${themeColor(BaseColor.shade_0)};
  fill: ${themeColor(Color.primary)};
`

export const LogoLink = styled(NavLink)`
  ${linkReset};
  flex-shrink: 0;
  margin-right: ${spacing(1)};

  @media (${headerMobileBreakpoint}) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin-right: 0;
  }
`
