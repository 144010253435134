// This function checks for URL change and sends those to the trackers we are using.
const initializeGATracking = () => {
  const { gtag } = window

  let lastURL = `${window.location.pathname}${window.location.search}`

  if (gtag) {
    gtag({
      event: 'virtualPageview',
      pageUrl: window.location.href,
      pageTitle: document.title,
    })
  }

  // HubSpot API is very old and still doesn't track SPAs automatically.
  // We need to manually tell it that used navigated from one page to another.
  // UPD: This seems to happen to Google Analytics as well.
  const checkAndTrack = () => {
    const newURL = `${window.location.pathname}${window.location.search}`

    if (newURL !== lastURL) {
      lastURL = newURL

      if (gtag) {
        gtag({
          event: 'virtualPageview',
          pageUrl: window.location.href,
          pageTitle: document.title,
        })
      }
    }

    window.requestAnimationFrame(checkAndTrack)
  }

  checkAndTrack()
}

export default initializeGATracking
