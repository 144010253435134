import {
  BaseColor,
  Breakpoint,
  breakpointStarting,
  Color,
  FlexibleContainer,
  rem,
  spacing,
  themeColor,
  Typography,
} from '@unbounded/unbounded-components'
import styled, { css } from 'styled-components'
import { headerHeight, headerMobileBreakpoint } from '~layout'

export const BaseContainer = styled(FlexibleContainer)`
  padding-left: ${spacing(1)};
  padding-right: ${spacing(1)};
`

export const Container = styled(BaseContainer)`
  padding-top: ${spacing(1)};
  padding-bottom: ${spacing(1)};
`

export const Page = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${themeColor(BaseColor.min)};
  overflow: hidden;

  @media (${headerMobileBreakpoint}) {
    margin-top: ${rem(headerHeight)};
  }
`

export const SectionTitle = styled.h2`
  ${Typography.titleSmall};
  ${Typography.textSemiStrong};
  text-align: center;
  text-transform: uppercase;
  color: ${themeColor(Color.primary)};
`

export const SectionLargeHeadline = styled.h3`
  ${Typography.headlineLarge};
  margin-top: ${spacing(1)};
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (${breakpointStarting(Breakpoint.md)}) {
    ${Typography.displayXL}
  }
`

export const SectionMediumHeadline = styled.h3`
  ${Typography.titleLarge};
  margin-top: ${spacing(2)};
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (${breakpointStarting(Breakpoint.md)}) {
    ${Typography.displayMedium}
  }
`

const circleFormer = css`
  background-color: ${themeColor(BaseColor.shade_90)};

  &::after {
    content: '';
    display: block;
    height: 4rem;
    background-color: ${themeColor(BaseColor.min)};

    @media (${breakpointStarting(Breakpoint.lg)}) {
      height: 6rem;
    }

    @media (${breakpointStarting(Breakpoint.xl)}) {
      height: 8rem;
    }
  }
`

export const ContrastSectionsOpener = styled.div`
  ${circleFormer};
  &::after {
    clip-path: ellipse(58% 200% at 50% 200%);
  }
`
export const Summary = styled(ContrastSectionsOpener)`
  display: flex;
  flex-direction: column;
  background-color: ${themeColor(BaseColor.shade_90)};
  min-height: 50vh;

  @media (${breakpointStarting(Breakpoint.sm)}) {
    min-height: 95vh;
  }
  @media (${breakpointStarting(Breakpoint.ultrahd)}) {
    min-height: 60vh;
  }

  ${BaseContainer} {
    flex: 1;
    display: flex;
  }
`

export const Community = styled.div`
  position: relative;
  background-color: ${themeColor(BaseColor.shade_90)};
  margin-bottom: ${spacing(2)};
  padding: ${spacing(2)} 0;

  @media (${breakpointStarting(Breakpoint.md)}) {
    margin-bottom: ${spacing(4)};
  }

  @media (${breakpointStarting(Breakpoint.lg)}) {
    margin-bottom: ${spacing(6)};
    padding: ${spacing(4)} 0;
  }
`

export const BasicSection = styled.div``

export const BecomeAPartner = styled(BasicSection)`
  padding: ${spacing(2)} 0;

  @media (${breakpointStarting(Breakpoint.lg)}) {
    padding: ${spacing(6)} 0;
  }
`
