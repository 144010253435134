import { Color, spacing, themeColor, Typography, StyledModal, link, InlineLabel } from '@unbounded/unbounded-components'
import styled from 'styled-components'

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: ${spacing(1)};
  margin-top: ${spacing(1)};
`

export const Actions = styled(StyledModal.Footer)``

export const Error = styled.div`
  ${Typography.labelMedium};
  margin-top: ${spacing()};
  color: ${themeColor(Color.danger)};
`

export const Agreement = styled(InlineLabel)`
  margin-top: ${spacing()};
`

export const Link = styled.a`
  ${link};
  ${Typography.textSemiStrong};
`
