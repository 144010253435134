import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { LoadingArea } from '@unbounded/unbounded-components'
import { useAuth } from './useAuth.hook'

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.isLoading && !auth.user) {
    return <LoadingArea isLoading={true} />
  }

  if (!auth.isLoading && !auth.user) {
    // Would be nice to redirect to id.unbounded.network based on env, along with
    // sending FROM location, so when they login, they can get redirected back to were they
    // were trying to get

    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

export default RequireAuth
