import { Picture, Button, useOnScreenWithMemory } from '@unbounded/unbounded-components'

import React, { useRef } from 'react'
import mipasaLogo from '~assets/landing/mipasa-logo.svg'
import walletLogo from '~assets/landing/wallet-logo.svg'
import papillonLogo from '~assets/landing/papillon-logo.svg'

import mipasaImageWebp from '~assets/landing/mipasa-image.webp'
import papillonBgWebp from '~assets/landing/papillon-image.webp'
import walletSquirrelWebp from '~assets/landing/squirrel.webp'

import mipasaImagePng from '~assets/landing/mipasa-image.png'
import papillonBgPng from '~assets/landing/papillon-image.png'
import walletSquirrelPng from '~assets/landing/squirrel.png'

import Paths from '~routes/paths'
import * as Styled from './ProductsSection.styles'

const ProductsSection: React.FC = () => {
  const ref01 = useRef<HTMLElement>(null)
  const isOnScreen01 = useOnScreenWithMemory(ref01)
  const ref02 = useRef<HTMLElement>(null)
  const isOnScreen02 = useOnScreenWithMemory(ref02)
  const ref03 = useRef<HTMLElement>(null)
  const isOnScreen03 = useOnScreenWithMemory(ref03)

  return (
    <Styled.Container>
      <Styled.Title>An Ecosystem of Unbounded Apps™</Styled.Title>
      <Styled.ProductsList>
        <Styled.Product ref={ref01} isShown={isOnScreen01}>
          <Styled.ProductSummary>
            <Styled.ProductLogo width={220} height={60} src={mipasaLogo} alt="MiPasa" />
            <Styled.ProductTitle>Data-driven decisions anytime, anywhere</Styled.ProductTitle>
            <Styled.ProductDescription>
              Verified data and tools with the infrastructure needed to make informed decisions based on data, insights and collaboration, at scale.
            </Styled.ProductDescription>
            <Styled.ProductActions>
              <Button href="https://app.mipasa.com" variant="outlined">
                Try MiPasa
              </Button>
            </Styled.ProductActions>
          </Styled.ProductSummary>
          <Styled.ProductImageContainer>
            <Picture webpSrc={mipasaImageWebp} pngSrc={mipasaImagePng} alt="Data-driven decisions anytime, anywhere" />
          </Styled.ProductImageContainer>
        </Styled.Product>
        <Styled.Product isInverted ref={ref02} isShown={isOnScreen02}>
          <Styled.ProductImageContainer>
            <Picture webpSrc={papillonBgWebp} pngSrc={papillonBgPng} alt="Developer Freedom" />
          </Styled.ProductImageContainer>
          <Styled.ProductSummary>
            <Styled.ProductLogo width={220} height={60} src={papillonLogo} alt="Papillon" />
            <Styled.ProductTitle>Freedom to develop and collaborate across multiple blockchains</Styled.ProductTitle>
            <Styled.ProductDescription>
              Work from anywhere, collaborate with others, access to infinitely scalable infrastructure using just a browser.
            </Styled.ProductDescription>
            <Styled.ProductActions>
              <Button href="https://papillon.unbounded.network/" variant="outlined">
                Try Papillon
              </Button>
            </Styled.ProductActions>
          </Styled.ProductSummary>
        </Styled.Product>
        <Styled.Product ref={ref03} isShown={isOnScreen03}>
          <Styled.ProductSummary>
            <Styled.ProductLogo width={315} height={35} src={walletLogo} alt="Unbounded Wallet" />
            <Styled.ProductTitle>World&apos;s most flexible multichain wallet</Styled.ProductTitle>
            <Styled.ProductDescription>With extra benefits for users who work in highly regulated environments.</Styled.ProductDescription>
            <Styled.ProductActions>
              <Button to={Paths.wallet} variant="outlined">
                Learn More
              </Button>
            </Styled.ProductActions>
          </Styled.ProductSummary>
          <Styled.ProductImageContainer>
            <Picture webpSrc={walletSquirrelWebp} pngSrc={walletSquirrelPng} alt="Wallet" />
          </Styled.ProductImageContainer>
        </Styled.Product>
      </Styled.ProductsList>
    </Styled.Container>
  )
}

export default ProductsSection
