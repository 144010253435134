import { useEffect, useState, useCallback } from 'react'
import { triggerToast } from '@unbounded/unbounded-components'
import { RestApiPaths } from '~api'
import { performAsyncAction, useReducerHook } from '~utils'
import { NotebookPublication } from '~types'
import { Actions, curriedActions } from './FeaturedList.actions'
import { reducer } from './FeaturedList.reducer'
import { State, ActionName } from './FeaturedList.types'
import { DEFAULT_DATA, onFetchFeaturedList } from './FeaturedList.consts'

export const defaultState: State = {
  data: DEFAULT_DATA,
  isLoading: true,
  isFirstLoad: true,
}

export default function useFeaturedList(
  { pageSize, pageNumber, tagsFilter }: { pageSize: number; pageNumber: number; tagsFilter: string[] },
  initialState = defaultState
) {
  const [localPageNumber, setPageNumber] = useState(pageNumber)
  const [localPageSize, setPageSize] = useState(pageSize)

  // eslint-disable-next-line
  const { state, actions } = useReducerHook<State, { [key in Actions]: any }>(initialState, curriedActions, reducer)

  const getTagsFilterParams = useCallback(
    () => (tagsFilter && tagsFilter.length > 0 ? tagsFilter.map(tag => `&tags[]=${tag}`) : ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagsFilter.length]
  )

  useEffect(() => {
    actions.onFetchFeaturedListTrigger()

    performAsyncAction<ActionName, NotebookPublication[]>({
      actions,
      actionName: onFetchFeaturedList,
      urlPath: `${
        RestApiPaths.publications
      }?type=notebook&access_level=public&page-size=${localPageSize}&page=${localPageNumber}&sort_by=published_at&sort_direction=desc${getTagsFilterParams()}`,
      onFailure: ({ error }) => triggerToast('error', error || `Something went wrong`),
    })
  }, [actions, localPageSize, localPageNumber, getTagsFilterParams])

  useEffect(() => {
    if (pageSize !== localPageSize) {
      setPageSize(pageSize)
    }
    if (pageNumber !== localPageNumber) {
      setPageNumber(pageNumber)
    }
  }, [pageSize, pageNumber, localPageSize, localPageNumber])

  return { ...state }
}
