import React from 'react'
import * as Styled from './GuestButtonsList.styles'
import { useIDM } from '~components/IDM'

const GuestButtonsList: React.FC = () => {
  const idm = useIDM()

  const handleLogin = () => {
    idm.login()
  }

  const handleRegister = () => {
    idm.register()
  }

  return (
    <Styled.List>
      <Styled.LoginButton variant="transparent" title="Log In" onClick={handleLogin}>
        Log In
      </Styled.LoginButton>

      <Styled.RegisterButton title="Sign Up" onClick={handleRegister}>
        Get Started
      </Styled.RegisterButton>
    </Styled.List>
  )
}

export default GuestButtonsList
