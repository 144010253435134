import React from 'react'
import { Icon, IconName } from '@unbounded/unbounded-components'
import * as Styled from '../Navigation/Navigation.styles'

const Notifications: React.FC = () => (
  <Styled.Link to="/notifications/activities">
    <Icon name={IconName.bell} size="large" />
  </Styled.Link>
)

export default Notifications
