import React, { Dispatch, SetStateAction } from 'react'
import { Maybe, User } from '~api/types.generated'

interface UserContextInfo {
  user: Maybe<User>
  isLoading: boolean
  signIn: () => void
  signOut: (callback?: () => void) => void
  setSignOutAwaitingConfirmation: Dispatch<SetStateAction<boolean>>
  isSignOutAwaitingConfirmation: boolean
}

const UserContext = React.createContext<UserContextInfo>({
  user: null,
  isLoading: true,
  signIn: () => {},
  signOut: () => {},
  isSignOutAwaitingConfirmation: false,
  setSignOutAwaitingConfirmation: () => {},
})

const UserProvider = UserContext.Provider
const UserConsumer = UserContext.Consumer

export { UserProvider, UserConsumer, UserContext }
