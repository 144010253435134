import { useCallback, useRef } from 'react'

export const useIsFirstPaint = () => {
  const isFirstRenderRef = useRef(true)

  const isFirstPaint = useCallback(() => {
    /* eslint-disable-next-line */
    const prevRef = isFirstRenderRef.current

    isFirstRenderRef.current = false

    return prevRef
  }, [])

  return isFirstPaint
}
