import { useMutation } from '@apollo/client'
import { Field, Input, triggerToast } from '@unbounded/unbounded-components'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Styled from './BecomePartnerSection.styles'
import { becomePartnerQuery } from './BecomePartnerSection.gql'
import Paths from '~routes/paths'

const BecomePartnerSection: React.FC = () => {
  const [name, setName] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [becomePartner, { data, loading: isLoading, error, reset }] = useMutation(becomePartnerQuery)

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()
      const message = company ? `Message sent from 'Partner with us'; Company: ${company}` : `Message sent from 'Partner with us'`

      await becomePartner({
        variables: {
          name,
          message,
          email,
        },
      })
    },
    [name, company, email, becomePartner]
  )

  useEffect(() => {
    if (data && !error) {
      triggerToast('success', 'Message was sent successfully')
      setName('')
      setCompany('')
      setEmail('')
      reset()
    }
  }, [data, error, reset])

  return (
    <Styled.Container>
      <Styled.Title>How to partner with us?</Styled.Title>
      <Styled.Form onSubmit={handleSubmit}>
        <Field fixedCaption>
          <Input name="name" value={name} onValueChange={setName} placeholder="Your Name" required />
        </Field>
        <Field fixedCaption>
          <Input name="company" value={company} onValueChange={setCompany} placeholder="Company/Organization" />
        </Field>
        <Field fixedCaption>
          <Input type="email" value={email} onValueChange={setEmail} name="email" placeholder="Email" required />
        </Field>

        <Styled.Agreement>
          By clicking submit you agree to our{' '}
          <Styled.Link as={Link} to={Paths.termsOfUse}>
            User Agreement
          </Styled.Link>{' '}
          and{' '}
          <Styled.Link as={Link} to={Paths.privacyPolicy}>
            {' '}
            Privacy Policy
          </Styled.Link>
          .
        </Styled.Agreement>

        <Styled.FormActions>
          <Styled.SubmitButton type="submit" variant="solid" size="large" isLoading={isLoading}>
            Submit
          </Styled.SubmitButton>
          {error && <Styled.Error>Something went wrong</Styled.Error>}
        </Styled.FormActions>
      </Styled.Form>
    </Styled.Container>
  )
}

export default BecomePartnerSection
