import React, { memo, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

const ScrollToTopCore = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const ScrollToTop = () => (
  <>
    <ScrollToTopCore />
    <Outlet />
  </>
)

ScrollToTop.displayName = 'ScrollToTop'

export default memo(ScrollToTop)
