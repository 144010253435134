import { BaseColor, Breakpoint, breakpointStarting, rem, spacing, themeColor, Typography } from '@unbounded/unbounded-components'
import styled from 'styled-components'

export const Container = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: ${spacing(3)};
  color: ${themeColor(BaseColor.shade_0)};
  background-color: ${themeColor(BaseColor.shade_90)};
  overflow: hidden;

  @media (${breakpointStarting(Breakpoint.lg)}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Figure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media (${breakpointStarting(Breakpoint.lg)}) {
    position: static;
    width: 50%;
  }
`

export const Image = styled.img`
  width: ${rem(300)};
  height: ${rem(276)};

  @media (${breakpointStarting(Breakpoint.sm)}) {
    width: ${rem(397)};
    height: ${rem(366)};
  }

  @media (${breakpointStarting(Breakpoint.md)}) {
    width: ${rem(450)};
    height: ${rem(415)};
  }

  @media (${breakpointStarting(Breakpoint.lg)}) {
    width: ${rem(499)};
    height: ${rem(461)};
  }

  @media (${breakpointStarting(Breakpoint.xxl)}) {
    width: ${rem(601)};
    height: ${rem(555)};
  }
`

export const Slogan = styled.div`
  flex: 0 1 0;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${spacing(1.5)};
  text-align: center;

  @media (${breakpointStarting(Breakpoint.lg)}) {
    order: -1;
    width: 50%;
    margin-bottom: ${spacing(2)};
    text-align: left;
  }
`

export const SloganTitle = styled.h1`
  ${Typography.displayMedium};
  max-width: ${rem(400)};
  margin: 0 auto;

  @media (${breakpointStarting(Breakpoint.sm)}) {
    ${Typography.displayXL};
    max-width: ${rem(450)};
  }

  @media (${breakpointStarting(Breakpoint.lg)}) {
    ${Typography.displayXXL};
    margin: 0;
  }
`
export const SloganPart = styled.span`
  @media (${breakpointStarting(Breakpoint.md)}) {
    white-space: nowrap;
  }
`

export const SloganText = styled.p`
  ${Typography.titleMedium};
  ${Typography.textRegular};
  max-width: ${rem(550)};
  margin: 0 auto;

  @media (${breakpointStarting(Breakpoint.sm)}) {
    ${Typography.titleMedium};
    ${Typography.textRegular};
  }

  @media (${breakpointStarting(Breakpoint.lg)}) {
    ${Typography.titleLarge};
    margin: 0;
  }
`
