import { BaseColor, Button, Color, spacing, themeColor } from '@unbounded/unbounded-components'
import styled from 'styled-components'
import { headerMobileBreakpoint } from '../../Header.consts'

const AuthButton = styled(Button)`
  color: ${themeColor(BaseColor.shade_0)};
  margin-right: ${spacing(0.5)};
  &:hover {
    background-color: ${themeColor(Color.primary)};
    color: ${themeColor(BaseColor.shade_0)};
  }
`

export const LoginButton = styled(AuthButton)``

export const RegisterButton = styled(AuthButton)``

export const List = styled.div`
  display: flex;
  align-items: center;

  @media (${headerMobileBreakpoint}) {
    display: none;
  }
`
