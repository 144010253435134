import { ApolloClient, InMemoryCache, Reference } from '@apollo/client'
import { RelayFieldPolicy } from '@apollo/client/utilities/policies/pagination'
import { relayStylePagination } from '@apollo/client/utilities'
import { cursorPaginationFieldPolicy } from './policies'

export type CacheField = 'projects' | 'consortiums' | 'organizations' | 'frameworks'

export type CacheTypePolicies = Record<CacheField, RelayFieldPolicy<Reference>>

const cacheTypePolicies: CacheTypePolicies = {
  frameworks: relayStylePagination(),
  projects: cursorPaginationFieldPolicy(['text', 'frameworks']),
  consortiums: cursorPaginationFieldPolicy(['text', 'frameworks']),
  organizations: cursorPaginationFieldPolicy(['text']),
}

const client = new ApolloClient({
  uri: '/api/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: cacheTypePolicies,
      },
    },
  }),
})

export default client
