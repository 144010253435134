import { BaseColor, Breakpoint, breakpointStarting, Color, rem, spacing, themeColor, Typography, Picture } from '@unbounded/unbounded-components'
import styled from 'styled-components'
import { SectionMediumHeadline } from '../LandingPage.styles'

const breakpoint = breakpointStarting(Breakpoint.lg)

export const Container = styled.section`
  color: ${themeColor(BaseColor.shade_0)};
  background-color: ${themeColor(BaseColor.shade_90)};
`

export const Title = styled(SectionMediumHeadline)`
  max-width: ${rem(300)};

  @media (${breakpointStarting(Breakpoint.md)}) {
    max-width: ${rem(800)};
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(5)};
  margin: ${spacing(2)} 0;
  padding: ${spacing(4)} ${spacing(2)};

  @media (${breakpointStarting(Breakpoint.md)}) {
    margin-top: ${spacing(4)};
    padding: 0;
  }

  @media (${breakpointStarting(Breakpoint.lg)}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const ItemIconFigure = styled.figure`
  display: flex;
  align-items: center;
`
export const ItemIcon = styled(Picture)`
  max-height: ${spacing(6)};
  margin: 0 auto;

  @media (${breakpointStarting(Breakpoint.lg)}) {
    margin-left: 0;
  }
`

export const Item = styled.article`
  flex-grow: 1;
  display: flex;
  gap: ${spacing(1)};
  flex-direction: column;
  text-align: center;

  @media (${breakpointStarting(Breakpoint.lg)}) {
    text-align: left;
  }
`

export const ItemTitle = styled.h4`
  ${Typography.titleLarge};
  ${Typography.textSemiStrong};
  color: ${themeColor(Color.primary)};
`
export const ItemDescription = styled.p`
  ${Typography.bodySmall};

  @media (${breakpoint}) {
    ${Typography.bodyMedium};
  }
`
