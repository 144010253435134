import React, { ErrorInfo, ReactNode } from 'react'
import styled from 'styled-components'
import { logger } from '~logger'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

export const ErrorMsg = styled.p`
  min-height: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
`

const MODULE_NAME = 'ErrorBoundary'

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error({
      category: MODULE_NAME,
      message: `'Uncaught error:', ${error}, ${errorInfo}`,
    })
  }

  public render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <ErrorMsg>Something went wrong, please reload the page.</ErrorMsg>
    }

    return children
  }
}

export default ErrorBoundary
