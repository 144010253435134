import React, { useState } from 'react'
import { User } from '~api'
import { isMobile } from '@unbounded/unbounded-components'
import * as Styled from './UserChip.styles'

interface ComponentProps {
  user: User
  displayUsername?: boolean
}

const UserChip: React.FC<ComponentProps> = ({ user: { userName, avatar: avatarSrc }, displayUsername = false }) => {
  const [isImageError, setImageError] = useState(false)
  const placeholder = userName.substring(0, 1).toUpperCase()
  const displayImage = avatarSrc && !isImageError

  return (
    <Styled.Container>
      <Styled.Avatar $placeholder={displayImage ? '' : placeholder}>
        {displayImage && <Styled.AvatarImage src={avatarSrc} alt={userName} loading="lazy" onError={() => setImageError(true)} />}
      </Styled.Avatar>
      {isMobile() || (displayUsername && <Styled.Name>{userName}</Styled.Name>)}
    </Styled.Container>
  )
}

export default UserChip
