import styled from 'styled-components'
import { BaseColor, themeColor, rem, spacing, Typography, textEllipsis } from '@unbounded/unbounded-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

interface AvatarProps {
  $placeholder: string
}

export const Avatar = styled.figure<AvatarProps>`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: 50%;
  overflow: hidden;

  color: ${themeColor(BaseColor.min)};
  background-color: ${themeColor(BaseColor.shade_40)};

  ${Typography.bodyMedium};
  ${Typography.textSemiStrong};

  user-select: none;

  &::before {
    content: ${({ $placeholder }) => `"${$placeholder}"`};
    // Makes it more centered vertically
    margin-bottom: 1px;
  }
`

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`

export const Name = styled.div`
  min-width: 0;
  max-width: ${rem(120)};
  margin-left: ${spacing(0.5)};
  color: ${themeColor(BaseColor.shade_60)};
  ${Typography.labelMedium};
  ${Typography.textSemiStrong};
  ${textEllipsis};
  letter-spacing: ${rem(0.2)};
`
