import * as Types from '../types.generated'

import { gql } from '@apollo/client'
import { UserDetailsFragmentDoc } from '../fragments/UserDetails.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const

export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    user: self {
      ...UserDetails
    }
  }
  ${UserDetailsFragmentDoc}
`

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options)
}
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options)
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>
export type GetCurrentUserQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: string
    identityID: string
    email: string
    avatar?: string | null
    userName: string
    createdAt: any
    confirmedAt?: any | null
    updatedAt?: any | null
    firstName: string
    lastName: string
    apiKey: string
    social: { __typename?: 'UserSocialInfo'; websiteUrl: string; blogUrl: string; facebookUrl: string; linkedInUrl: string; twitterUrl: string }
    company: { __typename?: 'UserCompanyInfo'; name: string; position: string }
    apiKeys: Array<{ __typename?: 'APIKey'; keyID: string; name: string; expiration?: any | null }>
  } | null
}
