import {
  BaseColor,
  border,
  Color,
  ColorShade,
  FlexibleContainer,
  linkReset,
  rem,
  spacing,
  themeColor,
  transitions,
  Typography,
} from '@unbounded/unbounded-components'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { headerMobileBreakpoint, headerHeight } from '../../Header.consts'

interface ListProps {
  onlyMobile?: boolean
}

export const List = styled.ul<ListProps>`
  display: flex;
  align-items: center;

  ${props =>
    props.onlyMobile &&
    css`
      display: none;

      @media (${headerMobileBreakpoint}) {
        display: flex;
      }
    `}
`

export const Item = styled.li`
  position: relative;
  display: flex;
`

export const LinkStyle = css`
  padding: ${rem(22)} ${spacing(0.5)};
  cursor: pointer;
`

const linkActiveLineStyle = css`
  content: '';
  position: absolute;
  left: ${spacing(0.5)};
  right: ${spacing(0.5)};
  bottom: 0;
  height: 2px;
  background-color: ${themeColor(Color.primary)};
`

const linkIndicatorStyle = css`
  content: '';
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: ${rem(2)};
  width: ${rem(4)};
  height: ${rem(4)};
  background-color: ${themeColor(Color.primary, ColorShade.strong)};
  border-radius: 50%;
`

interface LinkProps {
  $hasIndicator?: boolean
  $isAccent?: boolean
}

export const Link = styled(NavLink)<LinkProps>`
  ${LinkStyle};
  ${linkReset};
  ${Typography.labelLarge};
  ${Typography.textSemiStrong};
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  ${transitions(['color'])};

  & {
    color: ${props => (props.$isAccent ? themeColor(Color.primary, ColorShade.semiSoft) : themeColor(BaseColor.shade_40))};
  }

  &.active,
  &:hover {
    color: ${props => (props.$isAccent ? themeColor(Color.primary, ColorShade.soft) : themeColor(BaseColor.min))};
    text-decoration: none;
  }

  &.active::before {
    ${linkActiveLineStyle};
  }

  &::after {
    ${props => props.$hasIndicator && linkIndicatorStyle};
  }
`

export const HiringLink = styled(Link)`
  color: ${themeColor(Color.primary, ColorShade.semiStrong)};
  ${transitions(['color'])};

  &:hover {
    color: ${themeColor(Color.primary, ColorShade.semiSoft)};
  }
`

interface NavContainerProps {
  isOpen: boolean
}

export const NavContainer = styled.nav<NavContainerProps>`
  flex-grow: 1;
  background-color: ${themeColor(BaseColor.shade_90)};

  @media (${headerMobileBreakpoint}) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: calc(100vh - ${headerHeight}px);
    overflow-y: auto;
    padding: ${spacing(1.25)} 0;
    border-bottom: ${border};

    ${transitions(['transform', 'opacity', 'visibility'])};

    ${props =>
      !props.isOpen &&
      css`
        opacity: 0;
        visibility: hidden;
        transform: translate(0, 5%);
      `};

    ${List} {
      flex-direction: column;
      align-items: stretch;

      &:not(:last-of-type) {
        margin-bottom: ${spacing(0.75)};
      }
    }

    ${Link} {
      justify-content: flex-start;
      padding: ${spacing(0.5)} ${spacing(1)};
      font-weight: 400;

      &::before {
        width: 1px;
        left: 0;
        right: auto;
        top: ${spacing(0.5)};
        bottom: ${spacing(0.5)};
        height: auto;
      }
    }
  }
`

export const Wrapper = styled(FlexibleContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${headerMobileBreakpoint}) {
    display: block;
  }
`
