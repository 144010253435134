export const replaceProtocol = (explorerApiUrl: string) => {
  const replacementPairs: [string, string][] = [
    ['eth://', 'http://'],
    ['eths://', 'https://'],
  ]
  let replaced = explorerApiUrl

  replacementPairs.forEach(replacementPair => {
    replaced = explorerApiUrl.replace(replacementPair[0], replacementPair[1])
  })

  return replaced
}
