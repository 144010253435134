import { curriedActionCreator } from '~utils'

/* eslint-disable */
// todo - adjust actions types
export const curriedActions = {
  // async actions
  onFetchFeaturedListTrigger: curriedActionCreator<any, 'onFetchFeaturedListTrigger'>('onFetchFeaturedListTrigger'),
  onFetchFeaturedListSuccess: curriedActionCreator<any, 'onFetchFeaturedListSuccess'>('onFetchFeaturedListSuccess'),
  onFetchFeaturedListError: curriedActionCreator<any, 'onFetchFeaturedListError'>('onFetchFeaturedListError'),
}

export type Actions = `onFetchFeaturedListTrigger` | `onFetchFeaturedListSuccess` | `onFetchFeaturedListError`
