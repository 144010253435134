import { Connection } from './types'

export const parseCursorPaginationResponse = <TNode extends object>(data?: Connection<TNode>) => {
  if (!data) {
    return { data: [] as TNode[], pageInfo: undefined }
  }

  const { edges, pageInfo } = data

  return { data: edges.map(edge => edge.node), pageInfo }
}
