import { useMutation } from '@apollo/client'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Icon, IconName, Modal, InputBase as Input, triggerToast, themes, ThemeName } from '@unbounded/unbounded-components'
import JoinUsForm from './JoinUsForm'
import { subscribeToNewsletterQuery } from './JoinCommunity.gql'
import * as Styled from './JoinCommunity.styles'

const JoinCommunity: React.FC = memo(() => {
  const [email, setEmail] = useState<string>('')
  const [subscribeToNewsletter, { data, loading: isLoading, error, reset }] = useMutation(subscribeToNewsletterQuery)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    let resetTimeout: ReturnType<typeof setTimeout>

    if (data && !error) {
      triggerToast('success', 'Message was sent successfully')
      resetTimeout = setTimeout(() => {
        setIsModalVisible(false)
        setEmail('')
        reset()
      }, 1000)
    }
    return () => resetTimeout && clearTimeout(resetTimeout)
  }, [data, error, reset, setIsModalVisible, setEmail])

  const handleInputSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setIsModalVisible(true)
  }

  const handleFormSubmit = useCallback(
    async (firstName: string, lastName: string, givenEmail: string) => {
      await subscribeToNewsletter({
        variables: {
          email: givenEmail,
          firstName,
          lastName,
        },
      })
    },
    [subscribeToNewsletter]
  )

  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false)
  }, [setIsModalVisible])

  return (
    <>
      <Styled.Form onSubmit={handleInputSubmit}>
        <Styled.Title>Join our community</Styled.Title>

        <Input
          onValueChange={setEmail}
          value={email}
          size="small"
          suffixElement={
            <Styled.Submit type="submit">
              <Icon name={IconName.chevron_right} />
            </Styled.Submit>
          }
          placeholder="Enter your email"
          type="email"
          required
        />
      </Styled.Form>

      {isModalVisible && (
        // Back to the current theme (it is `unboundedLight` for now)
        <ThemeProvider theme={themes[ThemeName.unboundedLight]}>
          <Modal title="Subscribe to our Newsletter" isVisible={true} onClose={handleCloseModal} closeOnClickOutside={false}>
            <JoinUsForm
              email={email}
              onSubmit={handleFormSubmit}
              onCancel={handleCloseModal}
              isLoading={isLoading}
              isSuccess={Boolean(data)}
              error={error && 'Something went wrong'}
            />
          </Modal>
        </ThemeProvider>
      )}
    </>
  )
})

JoinCommunity.displayName = 'JoinCommunity'
export default JoinCommunity
