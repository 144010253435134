import { ApolloProvider } from '@apollo/client'
import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Redirect } from 'react-router6-redirect'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles, LoadingArea, ScrollbarStyle, ThemeName, themes, ToastContainer } from '@unbounded/unbounded-components'
import { apiClient } from '~api'
import Layout from '~layout'
import * as Sentry from '@sentry/react'
import ScrollToTop from '~components/ScrollToTop'
import ErrorBoundary from '~components/ErrorBoundary'
import LocalStyles from './App.styles'
import appRoutes from '~routes/routes'
import AuthProvider from './components/AuthProvider'
import { ModalConsumer, ModalProvider } from '~components/Modal'
import { IDMProvider } from '~components/IDM'
import Paths from '~routes/paths'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const theme = themes[ThemeName.unboundedLight]

const App: React.FC = () => (
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ScrollbarStyle />
      <LocalStyles />
      <ToastContainer />
      <ApolloProvider client={apiClient}>
        <AuthProvider>
          <IDMProvider>
            <BrowserRouter>
              <Suspense
                fallback={
                  <Layout>
                    <LoadingArea isLoading={true} />
                  </Layout>
                }
              >
                <ModalProvider>
                  <SentryRoutes>
                    <Route element={<ScrollToTop />}>
                      {appRoutes.map(route => (
                        <Route key={route.path} path={route.path} element={route.element} />
                      ))}
                    </Route>
                    <Route path="*" element={<Redirect to={Paths.landing} />} />
                  </SentryRoutes>

                  <ModalConsumer />
                </ModalProvider>
              </Suspense>
            </BrowserRouter>
          </IDMProvider>
        </AuthProvider>
      </ApolloProvider>
    </ThemeProvider>
  </ErrorBoundary>
)

export default App
