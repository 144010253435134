import React, { useCallback, useState } from 'react'
import { IconName, Dropdown, StyledDropdown, useConstCallback } from '@unbounded/unbounded-components'
import UserChip from '~components/UserChip'
import * as StyledNavi from '../Navigation/Navigation.styles'
import * as Styled from './UserMenuDropdown.styles'
import { useIDM, getIDMUrl } from '~components/IDM'
import { useAuth } from '~components/AuthProvider'

const UserMenuDropdown: React.FC = () => {
  const { user, signOut, isSignOutAwaitingConfirmation, setSignOutAwaitingConfirmation } = useAuth()
  const idm = useIDM()
  const idmURL = getIDMUrl()

  const handleSignOut = useConstCallback(() => {
    signOut(() => {
      idm.logout()
    })
  }, [idm, signOut])

  const [isOpen, setIsOpen] = useState(false)

  const handleDropdownOpenChange = useConstCallback((newIsOpen: boolean) => {
    if (!newIsOpen) {
      setSignOutAwaitingConfirmation(false)
    }

    setIsOpen(newIsOpen)
  }, [])

  return (
    <Dropdown on="hover" placement="bottom-end" shouldDismissOnClick={false} onOpenChange={handleDropdownOpenChange} isOpen={isOpen}>
      <StyledNavi.Link as="div">{user && <UserChip user={user} />}</StyledNavi.Link>

      <StyledDropdown.Content as={Styled.Content}>
        <StyledDropdown.LinkOption as="a" href={idmURL} target="_blank">
          <StyledDropdown.OptionIcon name={IconName.cog} />
          Profile settings
        </StyledDropdown.LinkOption>
        <StyledDropdown.Divider />

        <StyledDropdown.LinkOption $isAccent={isSignOutAwaitingConfirmation} as="button" type="button" onClick={handleSignOut}>
          <StyledDropdown.OptionIcon name={isSignOutAwaitingConfirmation ? IconName.circle_exclamation : IconName.door_in} />
          {isSignOutAwaitingConfirmation ? 'Click to confirm' : 'Sign out'}
        </StyledDropdown.LinkOption>
      </StyledDropdown.Content>
    </Dropdown>
  )
}

export default UserMenuDropdown
