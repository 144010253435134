import React, { ReactNode, useEffect, useState, useCallback, useMemo } from 'react'
import { useGetCurrentUserQuery } from '~api/queries/user.generated'
import { Maybe, User } from '~api/types.generated'
import * as Sentry from '@sentry/browser'
import { UserProvider } from './user.context'

const USER_DATA_POLL_INTERVAL = 5000

interface Props {
  children?: ReactNode
}

const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const [user, setUser] = useState<Maybe<User>>(null)
  const [isFetching, setIsFetching] = useState(true)
  const [isAwaitingSignOutConfirmation, setAwaitingSignOutConfirmation] = useState(false)
  const {
    data,
    refetch,
    loading: isLoading,
  } = useGetCurrentUserQuery({
    pollInterval: USER_DATA_POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (!isLoading) {
      setUser(data?.user || null)
    }

    setIsFetching(isLoading)
  }, [data?.user, isLoading])

  const handleSignIn = useCallback(async () => {
    const response = await refetch()
    const fetchedUser = response.data?.user

    setUser(fetchedUser || null)

    if (user) {
      Sentry.setUser({ email: user.email })
    }
  }, [refetch, user])

  const handleSignOut = useCallback(
    callback => {
      if (isAwaitingSignOutConfirmation) {
        setAwaitingSignOutConfirmation(false)
        setUser(null)

        Sentry.setUser(null)

        setIsFetching(false)

        callback()
      } else {
        setAwaitingSignOutConfirmation(true)
      }
    },
    [isAwaitingSignOutConfirmation]
  )

  useEffect(
    () => () => {
      setAwaitingSignOutConfirmation(false)
      setUser(null)
      setIsFetching(false)
    },
    []
  )

  return (
    <UserProvider
      value={useMemo(
        () => ({
          user,
          isLoading: isFetching,
          signIn: handleSignIn,
          signOut: handleSignOut,
          setSignOutAwaitingConfirmation: setAwaitingSignOutConfirmation,
          isSignOutAwaitingConfirmation: isAwaitingSignOutConfirmation,
        }),
        [user, handleSignIn, handleSignOut, isFetching, isAwaitingSignOutConfirmation]
      )}
    >
      {children}
    </UserProvider>
  )
}

export default AuthProvider
