import { createGlobalStyle } from 'styled-components'

const UnboundedStyles = createGlobalStyle`
  #root {
    height: 100%;
    min-height: 100%;
  }
`

export default UnboundedStyles
