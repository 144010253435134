import { Breakpoint, breakpointStarting, rem, spacing, Typography, Animate, Animatable } from '@unbounded/unbounded-components'
import styled, { css } from 'styled-components'
import { SectionLargeHeadline } from '../LandingPage.styles'

const breakpoint = breakpointStarting(Breakpoint.lg)

export const Container = styled.section``

export const Title = styled(SectionLargeHeadline)`
  max-width: ${rem(300)};

  @media (${breakpointStarting(Breakpoint.md)}) {
    max-width: ${rem(550)};
  }
`

export const ProductsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing(4)} 0;
  gap: ${spacing(4)};
`
export const ProductSummary = styled.div`
  flex-grow: 1;
  text-align: center;

  @media (${breakpoint}) {
    text-align: inherit;
    width: 50%;
    max-width: ${rem(600)};
  }
`
export const ProductLogo = styled.img`
  margin-bottom: ${spacing(2)};
  margin-left: auto;
  margin-right: auto;

  @media (${breakpoint}) {
    margin-left: 0;
    margin-right: 0;
  }
`

export const ProductTitle = styled.h4`
  ${Typography.headlineMedium};
  max-width: rem(450);
  margin-top: ${spacing(1.5)};

  @media (${breakpoint}) {
    ${Typography.displayMedium};
    text-align: inherit;
    max-width: none;
  }
`
export const ProductDescription = styled.p`
  ${Typography.bodySmall};
  margin-top: ${spacing(1.5)};

  @media (${breakpoint}) {
    text-align: inherit;
    ${Typography.bodyMedium};
  }
`
export const ProductActions = styled.div`
  margin-top: ${spacing(1.5)};
`

export const ProductImageContainer = styled.div`
  display: none;
  width: 50%;
  position: relative;
  max-height: ${rem(768)};
  max-width: ${rem(640)};

  @media (${breakpoint}) {
    display: block;
  }
`

interface ProductProps extends Animatable {
  isInverted?: boolean
}

export const Product = styled.article<ProductProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props =>
    !props.isInverted &&
    css`
      ${ProductImageContainer} {
        ${Animate.fadeRight};
      }
      ${ProductSummary} {
        ${Animate.fadeLeft};
      }
    `}

  ${props =>
    props.isInverted &&
    css`
      ${ProductImageContainer} {
        ${Animate.fadeLeft};
      }
      ${ProductSummary} {
        ${Animate.fadeRight};
      }
    `}


  ${props =>
    props.isInverted &&
    css`
      text-align: right;
      ${ProductLogo} {
        margin-left: auto;
      }
    `}

  @media (${breakpoint}) {
    gap: ${spacing(4)};
  }
`
