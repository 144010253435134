import styled from 'styled-components/macro'
import { Button, rem, spacing, Typography } from '@unbounded/unbounded-components'

export const Form = styled.form`
  max-width: ${rem(170)};
`

export const Title = styled.h4`
  ${Typography.titleSmall};
  ${Typography.textSemiStrong};
  margin-bottom: ${spacing()};
`

export const Submit = styled(Button).attrs({ color: 'base', shape: 'square', size: 'small', variant: 'transparent' })`
  position: relative;
  right: ${rem(-11)};
`
