import React from 'react'
import { Footer, Header } from '~layout'
import BecomePartnerSection from '~routes/LandingPage/BecomePartnerSection'
import InsightsSection from '~routes/LandingPage/InsightsSection'
import OnboardingSection from '~routes/LandingPage/OnboardingSection'
import PartnersSection from '~routes/LandingPage/PartnersSection'
import ProductsSection from '~routes/LandingPage/ProductsSection'
import SummarySection from '~routes/LandingPage/SummarySection'
import * as Styled from './LandingPage.styles'

const LandingPage: React.FC = () => (
  <Styled.Page>
    <Header />
    <Styled.Summary>
      <Styled.BaseContainer>
        <SummarySection />
      </Styled.BaseContainer>
    </Styled.Summary>
    <Styled.BasicSection>
      <Styled.Container>
        <Styled.SectionTitle>Our products</Styled.SectionTitle>
        <ProductsSection />
      </Styled.Container>
    </Styled.BasicSection>
    <Styled.BasicSection>
      <Styled.Container>
        <Styled.SectionTitle>Spotlights</Styled.SectionTitle>
        <InsightsSection />
      </Styled.Container>
    </Styled.BasicSection>
    <Styled.Community>
      <Styled.Container>
        <Styled.SectionTitle>Join our community</Styled.SectionTitle>
        <OnboardingSection />
      </Styled.Container>
    </Styled.Community>
    <Styled.BasicSection>
      <Styled.Container>
        <Styled.SectionTitle>Our Partners</Styled.SectionTitle>
        <PartnersSection />
      </Styled.Container>
    </Styled.BasicSection>
    <Styled.BecomeAPartner>
      <Styled.Container>
        <BecomePartnerSection />
      </Styled.Container>
    </Styled.BecomeAPartner>
    <Footer />
  </Styled.Page>
)

export default LandingPage
