import React from 'react'
import { useIsFirstPaint } from '~utils'
import LogoWithNameIcon from '~assets/unbounded-logo.svg'
import Navigation from './components/Navigation'
import * as Styled from './Header.styles'
import { useAuth } from '~components/AuthProvider'
import GuestButtonsList from './components/GuestButtonsList'
import UserButtonsList from './components/UserButtonsList'

const Header: React.FC = () => {
  const { user: currentUser } = useAuth()
  const isFirstPaint = useIsFirstPaint()

  const buttonsList = currentUser ? <UserButtonsList /> : <GuestButtonsList />

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.Inner>
          <Styled.LogoLink to="/">
            <Styled.Logo src={LogoWithNameIcon} />
          </Styled.LogoLink>
          <Navigation />
          {!isFirstPaint() && buttonsList}
        </Styled.Inner>
      </Styled.Wrapper>
    </Styled.Container>
  )
}

export default Header
