export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Address: any
  Bytes: any
  Bytes32: any
  Cursor: any
  Time: any
  TxStatus: any
  Uint: any
  Uint32: any
  Uint64: any
  Uint256: any
}

export type ApiKey = {
  __typename?: 'APIKey'
  expiration?: Maybe<Scalars['Time']>
  keyID: Scalars['String']
  name: Scalars['String']
}

export type Account = Node & {
  __typename?: 'Account'
  address: Scalars['Address']
  erc20TokenTransfers: Erc20TokenTransferConnection
  erc721TokenTransfers: Erc721TokenTransferConnection
  id: Scalars['ID']
  tokenContract?: Maybe<TokenContract>
  transactions: TransactionConnection
  type: AccountType
}

export type AccountErc20TokenTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: TokenTransferFilter
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AccountErc721TokenTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: TokenTransferFilter
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AccountTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: TransactionFilter
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type AccountConnection = {
  __typename?: 'AccountConnection'
  edges?: Maybe<Array<Maybe<AccountEdge>>>
  nodes?: Maybe<Array<Maybe<Account>>>
  pageInfo: PageInfo
}

export type AccountEdge = {
  __typename?: 'AccountEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<Account>
}

export type AccountFilter = {
  accountType?: InputMaybe<AccountType>
  startsWith?: InputMaybe<Scalars['String']>
  tokenType?: InputMaybe<TokenContractType>
}

export enum AccountType {
  Contract = 'CONTRACT',
  External = 'EXTERNAL',
  Unknown = 'UNKNOWN',
}

export type Block = Node & {
  __typename?: 'Block'
  hash: Scalars['Bytes32']
  id: Scalars['ID']
  number: Scalars['Uint64']
  parent: Block
  timestamp: Scalars['Time']
  transactionCount: Scalars['Uint32']
  transactions?: Maybe<TransactionConnection>
}

export type BlockTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type BlockConnection = {
  __typename?: 'BlockConnection'
  edges?: Maybe<Array<Maybe<BlockEdge>>>
  nodes?: Maybe<Array<Maybe<Block>>>
  pageInfo: PageInfo
}

export type BlockEdge = {
  __typename?: 'BlockEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<Block>
}

export type BlockchainFramework = Node & {
  __typename?: 'BlockchainFramework'
  description: Scalars['String']
  id: Scalars['ID']
  logo?: Maybe<LogoInformation>
  name: Scalars['String']
  slug: Scalars['String']
  socialUrls: Array<SocialUrl>
  websiteUrl: Scalars['String']
}

export type BlockchainFrameworkConnection = {
  __typename?: 'BlockchainFrameworkConnection'
  edges: Array<BlockchainFrameworkEdge>
  nodes: Array<BlockchainFramework>
  pageInfo: PageInfo
}

export type BlockchainFrameworkEdge = {
  __typename?: 'BlockchainFrameworkEdge'
  cursor: Scalars['Cursor']
  node: BlockchainFramework
}

export type ChainlinkAggregator = Node & {
  __typename?: 'ChainlinkAggregator'
  account: Account
  answers: ChainlinkAnswerConnection
  decimals?: Maybe<Scalars['Uint']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  typeAndVersion?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['Uint256']>
}

export type ChainlinkAggregatorAnswersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ChainlinkAggregatorConnection = {
  __typename?: 'ChainlinkAggregatorConnection'
  edges?: Maybe<Array<Maybe<ChainlinkAggregatorEdge>>>
  nodes?: Maybe<Array<Maybe<ChainlinkAggregator>>>
  pageInfo: PageInfo
}

export type ChainlinkAggregatorEdge = {
  __typename?: 'ChainlinkAggregatorEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<ChainlinkAggregator>
}

export type ChainlinkAggregatorFilter = {
  allowMissingDescription: Scalars['Boolean']
}

export type ChainlinkAnswer = Node & {
  __typename?: 'ChainlinkAnswer'
  id: Scalars['ID']
  logIndex: Scalars['Uint32']
  roundID: Scalars['Uint256']
  transaction: Transaction
  updatedAt: Scalars['Time']
  value: Scalars['Uint256']
}

export type ChainlinkAnswerConnection = {
  __typename?: 'ChainlinkAnswerConnection'
  edges?: Maybe<Array<Maybe<ChainlinkAnswerEdge>>>
  nodes?: Maybe<Array<Maybe<ChainlinkAnswer>>>
  pageInfo: PageInfo
}

export type ChainlinkAnswerEdge = {
  __typename?: 'ChainlinkAnswerEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<ChainlinkAnswer>
}

export type ChainlinkProxy = Node & {
  __typename?: 'ChainlinkProxy'
  account: Account
  answers: ChainlinkAnswerConnection
  assetName: Scalars['String']
  decimals: Scalars['Uint']
  deviationThreshold: Scalars['Float']
  feedCategory: Scalars['String']
  feedType: Scalars['String']
  heartbeat: Scalars['String']
  id: Scalars['ID']
  pair?: Maybe<Scalars['String']>
  phases?: Maybe<Array<ChainlinkProxyPhase>>
}

export type ChainlinkProxyAnswersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ChainlinkProxyConnection = {
  __typename?: 'ChainlinkProxyConnection'
  edges?: Maybe<Array<Maybe<ChainlinkProxyEdge>>>
  nodes?: Maybe<Array<Maybe<ChainlinkProxy>>>
  pageInfo: PageInfo
}

export type ChainlinkProxyEdge = {
  __typename?: 'ChainlinkProxyEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<ChainlinkProxy>
}

export type ChainlinkProxyFilter = {
  ticker?: InputMaybe<Scalars['String']>
}

export type ChainlinkProxyPhase = {
  __typename?: 'ChainlinkProxyPhase'
  aggregator: ChainlinkAggregator
  phaseID: Scalars['Int']
  startBlock: Scalars['Uint64']
}

export type Consortium = Node & {
  __typename?: 'Consortium'
  createdAt: Scalars['Time']
  createdBy?: Maybe<User>
  description: Scalars['String']
  frameworks: Array<BlockchainFramework>
  id: Scalars['ID']
  logo?: Maybe<LogoInformation>
  name: Scalars['String']
  organizations: Array<Organization>
  slug: Scalars['String']
  socialUrls: Array<SocialUrl>
  updatedAt?: Maybe<Scalars['Time']>
}

export type ConsortiumConnection = {
  __typename?: 'ConsortiumConnection'
  edges: Array<ConsortiumEdge>
  nodes: Array<Consortium>
  pageInfo: PageInfo
}

export type ConsortiumEdge = {
  __typename?: 'ConsortiumEdge'
  cursor: Scalars['Cursor']
  node: Consortium
}

export type ContactUsInput = {
  email: Scalars['String']
  message: Scalars['String']
  name: Scalars['String']
}

export type CreateApiKeyInput = {
  expiration?: InputMaybe<Scalars['Time']>
  name: Scalars['String']
}

export type CreateApiKeyPayload = {
  __typename?: 'CreateAPIKeyPayload'
  apiKey?: Maybe<ApiKey>
  value?: Maybe<Scalars['String']>
}

export type DeleteApiKeyInput = {
  keyID: Scalars['String']
}

export type DeleteApiKeyPayload = {
  __typename?: 'DeleteAPIKeyPayload'
  deleted?: Maybe<ApiKey>
}

export type Erc20TokenTransfer = Node & {
  __typename?: 'ERC20TokenTransfer'
  amount: Scalars['Uint256']
  from: Account
  id: Scalars['ID']
  logIndex: Scalars['Uint32']
  price?: Maybe<Scalars['Uint256']>
  to: Account
  token: TokenContract
  transaction: Transaction
}

export type Erc20TokenTransferPriceArgs = {
  denomination?: Scalars['String']
}

export type Erc20TokenTransferConnection = {
  __typename?: 'ERC20TokenTransferConnection'
  edges?: Maybe<Array<Maybe<Erc20TokenTransferEdge>>>
  nodes?: Maybe<Array<Maybe<Erc20TokenTransfer>>>
  pageInfo: PageInfo
}

export type Erc20TokenTransferEdge = {
  __typename?: 'ERC20TokenTransferEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<Erc20TokenTransfer>
}

export type Erc721TokenTransfer = Node & {
  __typename?: 'ERC721TokenTransfer'
  from: Account
  id: Scalars['ID']
  logIndex: Scalars['Uint32']
  to: Account
  token: TokenContract
  tokenID: Scalars['Uint256']
  transaction: Transaction
}

export type Erc721TokenTransferConnection = {
  __typename?: 'ERC721TokenTransferConnection'
  edges?: Maybe<Array<Maybe<Erc721TokenTransferEdge>>>
  nodes?: Maybe<Array<Maybe<Erc721TokenTransfer>>>
  pageInfo: PageInfo
}

export type Erc721TokenTransferEdge = {
  __typename?: 'ERC721TokenTransferEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<Erc721TokenTransfer>
}

export type JoinOurBetaInput = {
  country: Scalars['String']
  email: Scalars['String']
  message: Scalars['String']
  name: Scalars['String']
}

export type LogoInformation = {
  __typename?: 'LogoInformation'
  isBright: Scalars['Boolean']
  url: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  createAPIKey: CreateApiKeyPayload
  deleteAPIKey: DeleteApiKeyPayload
  submitBetaForm?: Maybe<Scalars['Boolean']>
  submitContactForm?: Maybe<Scalars['Boolean']>
  submitNewsletterForm?: Maybe<Scalars['Boolean']>
  submitPartnerWithUsForm?: Maybe<Scalars['Boolean']>
  updateAPIKeyName: UpdateApiKeyNamePayload
}

export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput
}

export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyInput
}

export type MutationSubmitBetaFormArgs = {
  input: JoinOurBetaInput
}

export type MutationSubmitContactFormArgs = {
  input: ContactUsInput
}

export type MutationSubmitNewsletterFormArgs = {
  input: SubscribeToNewsletterInput
}

export type MutationSubmitPartnerWithUsFormArgs = {
  input: PartnerWithUsInput
}

export type MutationUpdateApiKeyNameArgs = {
  input: UpdateApiKeyNameInput
}

export type Node = {
  id: Scalars['ID']
}

export type Organization = Node & {
  __typename?: 'Organization'
  consortiums: Array<Consortium>
  createdAt: Scalars['Time']
  createdBy?: Maybe<User>
  description: Scalars['String']
  id: Scalars['ID']
  logo?: Maybe<LogoInformation>
  name: Scalars['String']
  slug: Scalars['String']
  socialUrls: Array<SocialUrl>
  updatedAt?: Maybe<Scalars['Time']>
}

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection'
  edges: Array<OrganizationEdge>
  nodes: Array<Organization>
  pageInfo: PageInfo
}

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge'
  cursor: Scalars['Cursor']
  node: Organization
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['Cursor']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['Cursor']>
}

export type PartnerWithUsInput = {
  company?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  name: Scalars['String']
}

export type Project = Node & {
  __typename?: 'Project'
  approvalRequestedAt?: Maybe<Scalars['Time']>
  approvedAt?: Maybe<Scalars['Time']>
  approvedBy?: Maybe<User>
  createdAt: Scalars['Time']
  createdBy?: Maybe<User>
  description: Scalars['String']
  descriptionExcerpt: Scalars['String']
  explorerApiUrl: Scalars['String']
  frameworks: Array<BlockchainFramework>
  id: Scalars['ID']
  logo?: Maybe<LogoInformation>
  name: Scalars['String']
  slug: Scalars['String']
  socialUrls: Array<SocialUrl>
  termsOfUse: Scalars['String']
  type: ProjectType
  updatedAt?: Maybe<Scalars['Time']>
}

export type ProjectConnection = {
  __typename?: 'ProjectConnection'
  edges: Array<ProjectEdge>
  nodes: Array<Project>
  pageInfo: PageInfo
}

export type ProjectEdge = {
  __typename?: 'ProjectEdge'
  cursor: Scalars['Cursor']
  node: Project
}

export enum ProjectType {
  Company = 'company',
  Dapp = 'dapp',
  Network = 'network',
}

export type Query = {
  __typename?: 'Query'
  account: Account
  accounts: AccountConnection
  block?: Maybe<Block>
  blocks: BlockConnection
  chainlinkAggregators: ChainlinkAggregatorConnection
  chainlinkProxies: ChainlinkProxyConnection
  consortium?: Maybe<Consortium>
  consortiums: ConsortiumConnection
  framework?: Maybe<BlockchainFramework>
  frameworks: BlockchainFrameworkConnection
  node: Node
  organization?: Maybe<Organization>
  organizations: OrganizationConnection
  project?: Maybe<Project>
  projects: ProjectConnection
  self?: Maybe<User>
  tokenContractsBySymbol: TokenContractConnection
  transaction?: Maybe<Transaction>
  transactions: TransactionConnection
}

export type QueryAccountArgs = {
  address: Scalars['Address']
}

export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: InputMaybe<AccountFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryBlockArgs = {
  hash?: InputMaybe<Scalars['Bytes32']>
  number?: InputMaybe<Scalars['Uint64']>
}

export type QueryBlocksArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryChainlinkAggregatorsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: InputMaybe<ChainlinkAggregatorFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryChainlinkProxiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: InputMaybe<ChainlinkProxyFilter>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryConsortiumArgs = {
  slug: Scalars['String']
}

export type QueryConsortiumsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  frameworks?: InputMaybe<Array<Scalars['String']>>
  last?: InputMaybe<Scalars['Int']>
  text?: InputMaybe<Scalars['String']>
}

export type QueryFrameworkArgs = {
  slug: Scalars['String']
}

export type QueryFrameworksArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  all?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryNodeArgs = {
  id: Scalars['ID']
}

export type QueryOrganizationArgs = {
  slug: Scalars['String']
}

export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  text?: InputMaybe<Scalars['String']>
}

export type QueryProjectArgs = {
  slug: Scalars['String']
}

export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  frameworks?: InputMaybe<Array<Scalars['String']>>
  last?: InputMaybe<Scalars['Int']>
  text?: InputMaybe<Scalars['String']>
}

export type QueryTokenContractsBySymbolArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  symbol: Scalars['String']
}

export type QueryTransactionArgs = {
  hash: Scalars['Bytes32']
}

export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SocialUrl = {
  __typename?: 'SocialURL'
  type: SocialUrlType
  url: Scalars['String']
}

export enum SocialUrlType {
  Facebook = 'facebook',
  Linkedin = 'linkedin',
  Medium = 'medium',
  Twitter = 'twitter',
  Website = 'website',
  Youtube = 'youtube',
}

export type SubscribeToNewsletterInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
}

export type TokenContract = Node & {
  __typename?: 'TokenContract'
  account: Account
  decimals?: Maybe<Scalars['Uint']>
  erc20TokenTransfers: Erc20TokenTransferConnection
  erc721TokenTransfers: Erc721TokenTransferConnection
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Uint256']>
  symbol?: Maybe<Scalars['String']>
  totalSupply?: Maybe<TokenTotalSupply>
  type: TokenContractType
  veracity: TokenContractVeracity
}

export type TokenContractErc20TokenTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: TokenTransferFilter
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TokenContractErc721TokenTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: TokenTransferFilter
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TokenContractPriceArgs = {
  denomination?: Scalars['String']
}

export type TokenContractTotalSupplyArgs = {
  blockNumber?: InputMaybe<Scalars['Uint64']>
}

export type TokenContractConnection = {
  __typename?: 'TokenContractConnection'
  edges?: Maybe<Array<Maybe<TokenContractEdge>>>
  nodes?: Maybe<Array<Maybe<TokenContract>>>
  pageInfo: PageInfo
}

export type TokenContractEdge = {
  __typename?: 'TokenContractEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<TokenContract>
}

export enum TokenContractType {
  Erc20 = 'ERC20',
  Erc721 = 'ERC721',
}

export enum TokenContractVeracity {
  Fraudulent = 'Fraudulent',
  Suspicious = 'Suspicious',
  Unknown = 'Unknown',
  Vetted = 'Vetted',
}

export type TokenTotalSupply = {
  __typename?: 'TokenTotalSupply'
  blockNumber: Scalars['Uint64']
  supply: Scalars['Uint256']
}

export type TokenTransferFilter = {
  from?: InputMaybe<Array<Scalars['Address']>>
  to?: InputMaybe<Array<Scalars['Address']>>
  token?: InputMaybe<Array<Scalars['Address']>>
}

export type Transaction = Node & {
  __typename?: 'Transaction'
  block: Block
  erc20TokenTransfers: Erc20TokenTransferConnection
  erc721TokenTransfers: Erc721TokenTransferConnection
  from: Account
  gasLimit: Scalars['Uint64']
  gasPrice: Scalars['Uint256']
  hash: Scalars['Bytes32']
  id: Scalars['ID']
  nonce: Scalars['Uint64']
  price?: Maybe<Scalars['Uint256']>
  status: TransactionStatus
  to?: Maybe<Account>
  txIndex: Scalars['Uint32']
  value: Scalars['Uint256']
}

export type TransactionErc20TokenTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: TokenTransferFilter
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TransactionErc721TokenTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  before?: InputMaybe<Scalars['Cursor']>
  filter?: TokenTransferFilter
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type TransactionPriceArgs = {
  denomination?: Scalars['String']
}

export type TransactionConnection = {
  __typename?: 'TransactionConnection'
  edges?: Maybe<Array<Maybe<TransactionEdge>>>
  nodes?: Maybe<Array<Maybe<Transaction>>>
  pageInfo: PageInfo
}

export type TransactionEdge = {
  __typename?: 'TransactionEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<Transaction>
}

export type TransactionFilter = {
  from?: InputMaybe<Array<Scalars['Address']>>
  to?: InputMaybe<Array<InputMaybe<Scalars['Address']>>>
}

export enum TransactionStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Successful = 'SUCCESSFUL',
  Unknown = 'UNKNOWN',
}

export type UpdateApiKeyNameInput = {
  keyID: Scalars['String']
  name: Scalars['String']
}

export type UpdateApiKeyNamePayload = {
  __typename?: 'UpdateAPIKeyNamePayload'
  apiKey?: Maybe<ApiKey>
}

export type User = Node & {
  __typename?: 'User'
  apiKey: Scalars['String']
  apiKeys: Array<ApiKey>
  avatar?: Maybe<Scalars['String']>
  company: UserCompanyInfo
  confirmedAt?: Maybe<Scalars['Time']>
  createdAt: Scalars['Time']
  email: Scalars['String']
  firstName: Scalars['String']
  id: Scalars['ID']
  identityID: Scalars['String']
  lastName: Scalars['String']
  social: UserSocialInfo
  updatedAt?: Maybe<Scalars['Time']>
  userName: Scalars['String']
}

export type UserCompanyInfo = {
  __typename?: 'UserCompanyInfo'
  name: Scalars['String']
  position: Scalars['String']
}

export type UserSocialInfo = {
  __typename?: 'UserSocialInfo'
  blogUrl: Scalars['String']
  facebookUrl: Scalars['String']
  linkedInUrl: Scalars['String']
  twitterUrl: Scalars['String']
  websiteUrl: Scalars['String']
}
