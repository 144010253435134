import React, { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { LoadingArea } from '@unbounded/unbounded-components'
import { formatTime } from '~utils'
import mipasaLogo from '~assets/landing/mipasa-m.svg'
import * as Styled from './InsightsSection.styles'
import useFeaturedList from '../../Featured/FeaturedListPage/useFeaturedList.hook'
import { NotebookPublication } from '~types'
import Paths from '~routes/paths'

const InsightsSection: React.FC = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useFeaturedList({
    pageSize: 3,
    pageNumber: 1,
    tagsFilter: [],
  })

  const handleViewAllClick = useCallback(() => {
    navigate(Paths.featured)
  }, [navigate])

  return (
    <Styled.Container>
      <Styled.Title>Recent Models and Insights</Styled.Title>
      <LoadingArea isLoading={isLoading} placeholder="Loading latest insights...">
        <Styled.List>
          {data.entries.map((entry: NotebookPublication) => (
            <Styled.Link key={entry.id} href={generatePath(Paths.featuredDetails, { slug: entry.slug })}>
              <Styled.Item>
                <Styled.ItemTitle>{entry.title}</Styled.ItemTitle>
                <Styled.ItemDescription>{entry.description}</Styled.ItemDescription>
                <Styled.ItemCopyright>
                  <Styled.CopyrightLogo src={mipasaLogo} alt="MiPasa" />
                  Published on {formatTime(entry.published_at, true)}
                </Styled.ItemCopyright>
              </Styled.Item>
            </Styled.Link>
          ))}
        </Styled.List>
      </LoadingArea>
      <Styled.ViewAllContainer>
        <Styled.ViewAllButton onClick={handleViewAllClick} variant="outlined" size="small">
          View All
        </Styled.ViewAllButton>
      </Styled.ViewAllContainer>
    </Styled.Container>
  )
}

export default InsightsSection
