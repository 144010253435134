import React from 'react'
import createAccountImage from '~assets/landing/onboarding-create-account.png'
import createAccountImageWebp from '~assets/landing/onboarding-create-account.webp'
import listProjectImage from '~assets/landing/onboarding-list-project.png'
import listProjectImageWebp from '~assets/landing/onboarding-list-project.webp'
import stayInformedImage from '~assets/landing/onboarding-stay-informed.png'
import stayInformedImageWebp from '~assets/landing/onboarding-stay-informed.webp'
import * as Styled from './OnboardingSection.styles'

const OnboardingSection: React.FC = () => (
  <Styled.Container>
    <Styled.Title>Through our platform, you can access high-quality data and reliable connections</Styled.Title>
    <Styled.List>
      <Styled.Item>
        <Styled.ItemIconFigure>
          <Styled.ItemIcon width={68} height={96} webpSrc={createAccountImageWebp} pngSrc={createAccountImage} alt="Create acc img" />
        </Styled.ItemIconFigure>
        <Styled.ItemTitle>Create an Account</Styled.ItemTitle>
        <Styled.ItemDescription>
          Blockchain is a team sport — don&apos;t do it alone! Create a free account on the Unbounded Network and unlock everything the platform has
          to offer. Sign-up to connect with blockchain projects, consortiums & professionals around the world.
        </Styled.ItemDescription>
      </Styled.Item>

      <Styled.Item>
        <Styled.ItemIconFigure>
          <Styled.ItemIcon width={120} height={96} webpSrc={listProjectImageWebp} pngSrc={listProjectImage} alt="List project img" />
        </Styled.ItemIconFigure>
        <Styled.ItemTitle>List your Project or dApp</Styled.ItemTitle>
        <Styled.ItemDescription>
          Working on something cool, innovative, transformative? Great! Use the Unbounded Network to list your projects/dApp — our platform showcases
          and connects people with projects, projects with projects — built on the world&apos;s leading blockchains (Fabric, Ethereum, Corda...)
        </Styled.ItemDescription>
      </Styled.Item>

      <Styled.Item>
        <Styled.ItemIconFigure>
          <Styled.ItemIcon width={92} height={96} webpSrc={stayInformedImageWebp} pngSrc={stayInformedImage} alt="Stay informed img" />
        </Styled.ItemIconFigure>
        <Styled.ItemTitle>Stay Informed</Styled.ItemTitle>
        <Styled.ItemDescription>
          The blockchain ecosystem moves at the speed of light. we help you navigate and stay connected to what&apos;s going on in the most exciting
          technology space since the advent of the Internet.
        </Styled.ItemDescription>
      </Styled.Item>
    </Styled.List>
  </Styled.Container>
)

export default OnboardingSection
