import { Icon as IconComponent, transitions } from '@unbounded/unbounded-components'
import styled, { css } from 'styled-components'
import { headerMobileBreakpoint } from '../../Header.consts'
import { LinkStyle } from '../Navigation/Navigation.styles'

export const Container = styled.button`
  display: none;
  color: inherit;
  line-height: 1;
  ${LinkStyle};

  @media (${headerMobileBreakpoint}) {
    display: block;
  }
`

export const Inner = styled.span`
  position: relative;
  display: block;
`

interface IconProps {
  $isActive: boolean
}
export const Icon = styled(IconComponent)<IconProps>`
  ${transitions(['transform', 'opacity'])};

  &:not(:first-of-type) {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${props =>
    !props.$isActive &&
    css`
      ${transitions(['transform', 'opacity'])};
      opacity: 0;
      transform: rotate(-180deg) scale(0);
    `};
`
