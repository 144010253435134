export interface LoginSuccessMessage {
  base: 'unbounded-embed'
  type: 'login-success'
  code: string
}

export interface LogoutSuccessMessage {
  base: 'unbounded-embed'
  type: 'logout-success'
}

export interface FormSizeMessage {
  base: 'unbounded-embed'
  type: 'form-size'
  width: number
  height: number
}

export type EmbedMessage = LoginSuccessMessage | LogoutSuccessMessage | FormSizeMessage

export function isEmbedMessage(o: unknown): o is EmbedMessage {
  const checked = o as { base: string }

  return checked.base === 'unbounded-embed'
}
