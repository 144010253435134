/* eslint-disable no-console */
import * as Sentry from '@sentry/react'
import { SeverityLevel } from '@sentry/types'

enum LEVEL {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warn',
}

interface LogConfig {
  message: string
  category: string
  type?: string
}

export interface Logger {
  errorLog: (logConfig: LogConfig) => void
  infoLog: (logConfig: LogConfig) => void
  warnLog: (logConfig: LogConfig) => void
}

const getSentryLevel = (level: LEVEL): SeverityLevel => {
  switch (level) {
    case LEVEL.INFO:
      return 'info'
    case LEVEL.WARNING:
      return 'warning'
    case LEVEL.ERROR:
      return 'error'
    default:
      throw new Error(`Unknown level: ${level}`)
  }
}

const getLogMethod = (level: LEVEL) => {
  switch (level) {
    case LEVEL.INFO:
      return console.info
    case LEVEL.WARNING:
      return console.warn
    case LEVEL.ERROR:
      return console.error
    default:
      return console.log
  }
}

const log = (config: LogConfig & { level: LEVEL }) => {
  const date = new Date()

  if (__DEV__) {
    getLogMethod(config.level)(
      `${(date.toTimeString().substring(0, 8), date.toDateString())} [LOGGER]: [${config.category}]-[${config.level}]: ${config.message}`
    )
  } else {
    Sentry.addBreadcrumb({
      ...config,
      level: getSentryLevel(config.level),
      timestamp: date.getTime(),
    })
  }
}

const error = (config: LogConfig) => log({ ...config, level: LEVEL.ERROR })
const info = (config: LogConfig) => log({ ...config, level: LEVEL.INFO })
const warning = (config: LogConfig) => log({ ...config, level: LEVEL.WARNING })

export default { error, info, warning }
