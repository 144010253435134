import { Breakpoint, breakpointStarting, Button, Color, Field, link, rem, spacing, themeColor, Typography } from '@unbounded/unbounded-components'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${spacing(2)};

  @media (${breakpointStarting(Breakpoint.lg)}) {
    flex-direction: row;
    gap: ${spacing(4)};
  }
`

export const Title = styled.h3`
  ${Typography.headlineLarge};
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: ${rem(500)};
  flex-grow: 1;

  @media (${breakpointStarting(Breakpoint.lg)}) {
    ${Typography.displayXL};
    text-align: left;
  }
`
export const Agreement = styled.div`
  margin-bottom: ${spacing()};
`

export const Link = styled.a`
  ${link};
  ${Typography.textSemiStrong};
`

export const Form = styled.form`
  flex-grow: 1;
`
export const FormActions = styled(Field).attrs({ as: 'div' })`
  display: flex;
  align-items: center;
`

export const Error = styled.div`
  margin-left: ${spacing()};
  ${Typography.labelMedium};
  color: ${themeColor(Color.danger)};
`

export const SubmitButton = styled(Button)`
  width: ${rem(170)};
`
