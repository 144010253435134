import { Button, Checkbox, Field, InlineLabel, Input } from '@unbounded/unbounded-components'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import Paths from '~routes/paths'
import * as Styled from './JoinUsForm.styles'

interface Props {
  email: string
  onCancel: () => void
  onSubmit: (firstName: string, lastName: string, email: string) => void
  isLoading?: boolean
  error?: string
  isSuccess?: boolean
}

const JoinUsForm: React.FC<Props> = ({ email: emailPreset, onSubmit, onCancel, isLoading, isSuccess, error }) => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [hasPolicyAgreement, setHasPolicyAgreement] = useState<boolean>(false)
  const [email, setEmail] = useState<string>(emailPreset)

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      onSubmit(firstName, lastName, email)
    },
    [firstName, lastName, email, onSubmit]
  )

  return (
    <form onSubmit={handleSubmit}>
      <Styled.Fieldset>
        <Field label="First name *">
          <Input value={firstName} onValueChange={setFirstName} name="firstName" autoComplete="given-name" required />
        </Field>
        <Field label="Last name *">
          <Input value={lastName} onValueChange={setLastName} name="lastName" autoComplete="family-name" required />
        </Field>
        <Field label="Email *">
          <Input type="email" value={email} onValueChange={setEmail} name="email" required />
        </Field>
      </Styled.Fieldset>

      <Styled.Agreement>
        <InlineLabel
          label={
            <>
              Yes, I have read, understand and accept the{' '}
              <Styled.Link as={Link} to={Paths.termsOfUse}>
                {' '}
                Terms of Use
              </Styled.Link>{' '}
              and{' '}
              <Styled.Link as={Link} to={Paths.privacyPolicy}>
                {' '}
                Privacy Policy
              </Styled.Link>
              .
            </>
          }
        >
          <Checkbox checked={hasPolicyAgreement} onChange={() => setHasPolicyAgreement(!hasPolicyAgreement)} />
        </InlineLabel>
      </Styled.Agreement>

      {error && <Styled.Error>Something went wrong</Styled.Error>}

      <Styled.Actions>
        <Button type="button" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button type="submit" disabled={!hasPolicyAgreement || !firstName || !lastName || !email} isLoading={isLoading} isSuccess={isSuccess}>
          Subscribe
        </Button>
      </Styled.Actions>
    </form>
  )
}

export default JoinUsForm
