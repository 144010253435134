import { useCallback, useState } from 'react'

type ToggleCallback = () => void
type UseToggle = [boolean, ToggleCallback, ToggleCallback, ToggleCallback]

export const useToggle = (initState?: boolean): UseToggle => {
  const [isOn, setIsOn] = useState<boolean>(!!initState)

  const toggle = useCallback<ToggleCallback>(() => {
    setIsOn(v => !v)
  }, [setIsOn])

  const turnOn = useCallback<ToggleCallback>(() => {
    setIsOn(true)
  }, [setIsOn])

  const turnOff = useCallback<ToggleCallback>(() => {
    setIsOn(false)
  }, [setIsOn])

  return [isOn, toggle, turnOn, turnOff]
}
