import axios from 'axios'
import { isNil } from 'ramda'

export const BASE_URL = '/api/'

const unboundedApiVersion = 'v1/'
const mipasaNotebooksApiVersion = 'mipasa/v1/'

const client = axios.create({
  baseURL: BASE_URL,
  auth: {
    username: process.env.REACT_APP_BASIC_AUTH_USERNAME || '',
    password: process.env.REACT_APP_BASIC_AUTH_PASSWORD || '',
  },
})

export interface RestApiResponse<Data> {
  success: boolean
  error?: string
  data?: Data
  entries?: Data
}

export const RestApiPaths = {
  datasets: `${unboundedApiVersion}datasets`,
  notifications: `${mipasaNotebooksApiVersion}notifications`,
  notebooks: `${mipasaNotebooksApiVersion}notebooks`,
  publications: `${mipasaNotebooksApiVersion}publications`,
}

export enum DatasetVisibility {
  public = 'public',
  private = 'private',
}

const DatasetsPaths = {
  files: `/files`,
  thumbnail: `/thumbnail`,
  upload: `/upload`,
  uploadComplete: `/upload-complete`,
}

export function isExtendedResponse<Data>(response: RestApiResponse<Data> | Data): response is RestApiResponse<Data> {
  return (response as RestApiResponse<Data>).success !== undefined
}

export function extendResponse<Data>(data?: Data): RestApiResponse<Data> {
  return {
    success: !isNil(data),
    data,
  }
}

export const getNotebookPublicationUrlBySlug = (slug: string, withPayload?: boolean) =>
  `${RestApiPaths.publications}/by_slug/${slug}${withPayload ? '?with_payload=true' : ''}`

export const getNotebookRelativeFileUrl = (id: string, fileName: string) =>
  `${RestApiPaths.notebooks}/${id}/file_raw_contents/by_file_name/${fileName}`

export const getDatasetsUrl = (visibility: DatasetVisibility) => `${RestApiPaths.datasets}?visibility=${visibility}`

export const getDatasetUrl = (uuid: string) => `${RestApiPaths.datasets}/${uuid}`

export const getFileUrl = (uuid: string, fileUuid: string) => `${getDatasetUrl(uuid)}${DatasetsPaths.files}/${fileUuid}`

export const getFileSrc = (uuid: string, fileUuid: string) => `${BASE_URL}${getFileUrl(uuid, fileUuid)}`

export const getFileUploadUrl = (uuid: string) => `${getDatasetUrl(uuid)}${DatasetsPaths.files}${DatasetsPaths.upload}`

export const getFileUploadCompleteUrl = (uuid: string) => `${getDatasetUrl(uuid)}${DatasetsPaths.files}${DatasetsPaths.uploadComplete}`

export const getThumbnailUploadUrl = (uuid: string) => `${getDatasetUrl(uuid)}${DatasetsPaths.thumbnail}${DatasetsPaths.upload}`

export const getThumbnailUploadCompleteUrl = (uuid: string) => `${getDatasetUrl(uuid)}${DatasetsPaths.thumbnail}${DatasetsPaths.uploadComplete}`

const uuidPattern = '[0-9a-fA-F-]+'

export const responseValidators = {
  default: (data: unknown) => !isNil(data),
  [getFileUrl(uuidPattern, uuidPattern)]: () => true,
}

export default client
