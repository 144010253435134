import React from 'react'
import globe from '~assets/landing/unbounded-supernova.svg'
import * as Styled from './SummarySection.styles'

const SummarySection: React.FC = () => (
  <Styled.Container>
    <Styled.Figure>
      <Styled.Image src={globe} />
    </Styled.Figure>
    <Styled.Slogan>
      <Styled.SloganTitle>
        <Styled.SloganPart>Every network.</Styled.SloganPart> <Styled.SloganPart>Connected.</Styled.SloganPart>
      </Styled.SloganTitle>
      <Styled.SloganText>Connecting people, apps & ecosystems across blockchain networks, using multiple trust layers.</Styled.SloganText>
    </Styled.Slogan>
  </Styled.Container>
)

export default SummarySection
