export const onFetchFeaturedList = 'onFetchFeaturedList'

export const DEFAULT_DATA = {
  entries: [],
  page_number: 1,
  page_size: 10,
  total_entries: 0,
  total_pages: 0,
  tags: [],
}

export enum FilterParam {
  tag = 'tag',
}

export const FILTER_SECTIONS = []
