import React from 'react'
import { Picture } from '@unbounded/unbounded-components'

import ibmLogoPng from '~assets/landing/partner_ibm.png'
import microsoftLogoPng from '~assets/landing/partner_microsoft.png'
import linuxLogoPng from '~assets/landing/partner_linuxf.png'
import oracleLogoPng from '~assets/landing/partner_oracle.png'
import digitalAssetLogoPng from '~assets/landing/partner_digital_asset.png'
import hyperledgerLogoPng from '~assets/landing/partner_hyperledger.png'
import ttfLogoPng from '~assets/landing/partner_ttf.png'
import eeaLogoPng from '~assets/landing/partner_eea.png'

import ibmLogoWebp from '~assets/landing/partner_ibm.webp'
import microsoftLogoWebp from '~assets/landing/partner_microsoft.webp'
import linuxLogoWebp from '~assets/landing/partner_linuxf.webp'
import oracleLogoWebp from '~assets/landing/partner_oracle.webp'
import digitalAssetLogoWebp from '~assets/landing/partner_digital_asset.webp'
import hyperledgerLogoWebp from '~assets/landing/partner_hyperledger.webp'
import ttfLogoWebp from '~assets/landing/partner_ttf.webp'
import eeaLogoWebp from '~assets/landing/partner_eea.webp'

import * as Styled from './PartnersSection.styles'

const PartnersSection: React.FC = () => (
  <Styled.Container>
    <Styled.Title>Join us and be part of the ecosystem</Styled.Title>
    <Styled.List>
      <Styled.Item href="https://www.ibm.com/">
        <Picture webpSrc={ibmLogoWebp} pngSrc={ibmLogoPng} alt="Ibm logo" />
      </Styled.Item>
      <Styled.Item href="https://www.microsoft.com/">
        <Picture webpSrc={microsoftLogoWebp} pngSrc={microsoftLogoPng} alt="Microsoft logo" />
      </Styled.Item>
      <Styled.Item href="https://www.linuxfoundation.org/">
        <Picture webpSrc={linuxLogoWebp} pngSrc={linuxLogoPng} alt="Linux logo" />
      </Styled.Item>
      <Styled.Item href="https://www.oracle.com/">
        <Picture webpSrc={oracleLogoWebp} pngSrc={oracleLogoPng} alt="Oracle logo" />
      </Styled.Item>
      <Styled.Item href="https://www.digitalasset.com/">
        <Picture webpSrc={digitalAssetLogoWebp} pngSrc={digitalAssetLogoPng} alt="Digital Asset logo" />
      </Styled.Item>
      <Styled.Item href="https://www.hyperledger.org/">
        <Picture webpSrc={hyperledgerLogoWebp} pngSrc={hyperledgerLogoPng} alt="Hyperledger logo" />
      </Styled.Item>
      <Picture webpSrc={ttfLogoWebp} pngSrc={ttfLogoPng} alt="TTF logo" />
      <Styled.Item href="https://entethalliance.org/">
        <Picture webpSrc={eeaLogoWebp} pngSrc={eeaLogoPng} alt="EEA logo" />
      </Styled.Item>
    </Styled.List>
  </Styled.Container>
)

export default PartnersSection
