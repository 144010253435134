/**
 * Format time for date cells
 */
export const formatTime = (timestamp: string | number, withoutTime?: boolean) => {
  const fullDate = new Date(timestamp)

  const timeString = fullDate.toTimeString()
  const dateString = fullDate.toLocaleDateString()
  const invalidString = 'Invalid Date'

  if (timeString === invalidString || dateString === invalidString) {
    return invalidString
  }

  const time = timeString.slice(0, 8)

  return `${dateString}${withoutTime ? '' : ` ${time}`}`
}

export function formatTimeAgo(timestamp: string | number) {
  if (!timestamp) {
    return 'Never'
  }

  const fullDate = new Date(timestamp)
  const invalidString = 'Invalid Date'

  if (fullDate.toString() === invalidString) {
    return 'Invalid date'
  }

  const diff = (new Date().getTime() - fullDate.getTime()) / 1000

  if (diff < 0) {
    return 'Just now'
  }
  if (diff > 23 * 3600) {
    return formatTime(timestamp, true)
  }
  if (diff > 3600) {
    const hoursDiff = Math.round(diff / 3600)

    return `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ago`
  }
  if (diff > 60) {
    const minuteDiff = Math.round(diff / 60)

    return `${minuteDiff} minute${minuteDiff > 1 ? 's' : ''} ago`
  }
  const secondsDiff = Math.round(diff)

  return `${secondsDiff} second${secondsDiff > 1 ? 's' : ''} ago`
}
