import styled from 'styled-components'

export const IDMWrapper = styled.div`
  position: relative;
  min-width: 128px;
  min-height: 128px;
`

export const IDMFrame = styled.iframe<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: block;
`
