import { always, defaultTo, ifElse, includes, last, pipe, split } from 'ramda'

// eslint-disable-next-line
// @ts-ignore
const innerGetFileExtension: (s: string) => string = pipe(
  //
  split('.'),
  last,
  defaultTo('')
)

export const getFileExtension = ifElse(
  //
  includes('.'),
  innerGetFileExtension,
  always('')
)
