import * as Types from '../types.generated'

import { gql } from '@apollo/client'
export const UserDetailsFragmentDoc = gql`
  fragment UserDetails on User {
    id
    identityID
    email
    avatar
    userName
    createdAt
    confirmedAt
    updatedAt
    social {
      websiteUrl
      blogUrl
      facebookUrl
      linkedInUrl
      twitterUrl
    }
    company {
      name
      position
    }
    firstName
    lastName
    apiKey
    apiKeys {
      keyID
      name
      expiration
    }
  }
`
export type UserDetailsFragment = {
  __typename?: 'User'
  id: string
  identityID: string
  email: string
  avatar?: string | null
  userName: string
  createdAt: any
  confirmedAt?: any | null
  updatedAt?: any | null
  firstName: string
  lastName: string
  apiKey: string
  social: { __typename?: 'UserSocialInfo'; websiteUrl: string; blogUrl: string; facebookUrl: string; linkedInUrl: string; twitterUrl: string }
  company: { __typename?: 'UserCompanyInfo'; name: string; position: string }
  apiKeys: Array<{ __typename?: 'APIKey'; keyID: string; name: string; expiration?: any | null }>
}
